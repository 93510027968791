import React, { useCallback, useMemo } from 'react';
import DropdownController, {
  DropdownControllerProps,
} from '@components/form/DropdownController';
import * as businessDetailsConstants from '@components/form/account/constants';
import * as formConstants from '@components/form/constants';
import useIntl from 'src/shared/i18n/useIntl';
import { BusinessAddress } from 'src/shared/models/account';
import map from 'lodash/map';
import compact from 'lodash/compact';
import join from 'lodash/join';
import styled from 'styled-components';
import ObjectInfo from '@components/ObjectInfo';
import find from 'lodash/find';

type BusinessAddressDropdownProps = {
  businessAddresses: BusinessAddress[];
  onAddressChange?: () => void;
};

const StyledDropdownController = styled(DropdownController)`
  > div:last-child {
    margin-inline-start: 0px;
  }
`;

const BusinessAddressDropdown = (props: BusinessAddressDropdownProps) => {
  const { intl } = useIntl();
  const { businessAddresses, onAddressChange } = props;

  const getAddressValue = useCallback((address: BusinessAddress) => {
    return join(
      compact([
        address.addressLine1,
        address.addressLine2,
        address.city,
        address.state,
        address.zipCode,
        address.countryCode,
      ]),
      ', ',
    );
  }, []);

  const getAddressNode = useCallback(
    (address: BusinessAddress) => {
      const addressValue = getAddressValue(address);

      return (
        <>
          <ObjectInfo
            value={{
              title: address.businessName,
              description: addressValue,
            }}
          />
        </>
      );
    },
    [getAddressValue],
  );

  const requiredValidation = useMemo(
    () => ({
      required: intl.getMessage(
        formConstants.REQUIRED_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
      ),
    }),
    [intl],
  );

  const addressItems = useMemo(() => {
    return map(businessAddresses, (address) => {
      return {
        value: address.addressToken,
        label: getAddressNode(address),
      };
    });
  }, [businessAddresses]);

  const onOverrideLabel = useCallback<
    NonNullable<DropdownControllerProps['onOverrideLabel']>
  >(
    (currentLabel, selectedValue, overrideDropdownLabel) => {
      const addressItem = find(businessAddresses, {
        addressToken: selectedValue as string,
      });

      if (addressItem) {
        return getAddressValue(addressItem);
      }
      return overrideDropdownLabel;
    },
    [businessAddresses, getAddressValue],
  );

  return (
    <>
      <StyledDropdownController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_FIELD_ID}
        controllerName={businessDetailsConstants.SELECTED_ADDRESS_ID}
        items={addressItems || []}
        registerOptions={{
          ...requiredValidation,
        }}
        overrideDropdownLabel={`${intl.getMessage(
          businessDetailsConstants.BUSINESS_ADDRESS_DROPDOWN_DEFAULT_LABEL,
        )}`}
        onOverrideLabel={onOverrideLabel}
        callback={onAddressChange}
      />
    </>
  );
};

export default BusinessAddressDropdown;
