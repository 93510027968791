import React, { ReactElement } from 'react';
import { Text } from '@amzn/storm-ui';
import { CustomFormRow } from './style';

interface ReadOnlyFormFieldProps {
  label: string;
  id: string;
  value?: string;
  labelTopSpacing?: string;
  children?: ReactElement;
}

const ReadOnlyFormField = ({
  label,
  id,
  value,
  labelTopSpacing = '0px',
  children,
}: ReadOnlyFormFieldProps) => {
  return (
    <CustomFormRow
      label={label}
      labelTopSpacing={labelTopSpacing}
      id={id}
      controlComponents={[
        (componentId: string) => (
          <>
            {children ? (
              children
            ) : (
              <Text
                id={componentId}
                data-testid={`${id}-value`}
                textColor="secondary"
              >
                {value}
              </Text>
            )}
          </>
        ),
      ]}
    />
  );
};

export default ReadOnlyFormField;
