/**
 * Banner
 */
export const DEFAULT_BANNER_DURATION = 5000;
export const DEFAULT_ERROR_BANNER_DURATION = 8000;
export const BANNER_SUCCESS_ID_SUFFIX = 'success-banner';
export const BANNER_ERROR_ID_SUFFIX = 'error-banner';
export const BANNER_WARNING_ID_SUFFIX = 'warning-banner';
export const BANNER_INFO_ID_SUFFIX = 'info-banner';
export const BANNER_CLASS_NAME = 'banner';
export const BANNER_INIT_ERROR =
  'You must initialize the message or the element props';

/**
 * Snackbar
 */
export const DEFAULT_SNACKBAR_DURATION = 5000;
export const DEFAULT_ERROR_SNACKBAR_DURATION = 8000;
export const SNACKBAR_SUCCESS_ID_SUFFIX = 'success-snackbar';
export const SNACKBAR_ERROR_ID_SUFFIX = 'error-snackbar';
export const SNACKBAR_WARNING_ID_SUFFIX = 'warning-snackbar';
export const SNACKBAR_LOADING_ID_SUFFIX = 'loading-snackbar';
export const SNACKBAR_CLASS_NAME = 'snackbar';
