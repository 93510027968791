import React from 'react';
import useIntl from '@i18n/useIntl';
import { Text } from '@amzn/storm-ui';
import { SellingAccountsTitleWrapper } from 'src/pages/registration/style';
import {
  SELLING_ACCOUNT_MESSAGE_TRANSLATION_KEY,
  SELLING_ACCOUNT_TITLE_TRANSLATION_KEY,
} from 'src/pages/registration/components/SellingAccounts/constants';

const SellingAccountsTitle = () => {
  const { intl } = useIntl();
  return (
    <SellingAccountsTitleWrapper>
      <Text type="h4" fontSize="medium">
        {intl.getMessage(SELLING_ACCOUNT_TITLE_TRANSLATION_KEY)}
      </Text>
      <Text fontSize="base">
        {intl.getMessage(SELLING_ACCOUNT_MESSAGE_TRANSLATION_KEY)}
      </Text>
    </SellingAccountsTitleWrapper>
  );
};

export default SellingAccountsTitle;
