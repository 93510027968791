import { useEffect } from 'react';

const useDuration = (duration: number, callback: () => void) => {
  useEffect(() => {
    if (duration === 0) {
      return;
    }

    const timerId = window.setTimeout(callback, duration);
    return () => clearTimeout(timerId);
  }, [duration]);
};

export default useDuration;
