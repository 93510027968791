import React, { FC } from 'react';
import {
  MediumMarginDiv,
  LayoutTDHeading,
  LayoutTDBody,
  TextShiftedUp,
  MarketplaceInfoIcon,
} from '../style';
import { FormSelectedMarketplace, FormValues } from '../model';
import { UseFormWatch } from 'react-hook-form';
import { useBundle } from '@amzn/react-arb-tools';

type MarketplacesErrorsComponentProps = {
  watch: UseFormWatch<FormValues>;
};

const SelectedMarketplacesTextComponent: FC<
  MarketplacesErrorsComponentProps
> = ({ watch }) => {
  const marketplaces: FormSelectedMarketplace[] = watch('selectedMarketplaces');
  const selectedMarketplacesCount = marketplaces.filter(
    (m) => m.selected,
  ).length;
  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');
  if (isBundleLoading) {
    return null;
  }
  return (
    <>
      <LayoutTDHeading />
      <LayoutTDBody>
        <TextShiftedUp textColor="tertiary">
          <MarketplaceInfoIcon type="info-circle" size="sm" />{' '}
          {intl.getMessage('aum_aa_create_tooltip_marketplaces_account_names')}
          {selectedMarketplacesCount === 0
            ? ''
            : selectedMarketplacesCount === 1
            ? ` (${selectedMarketplacesCount} ${intl.getMessage(
                'aum_aa_create_account_total',
              )}`
            : ` (${selectedMarketplacesCount} ${intl.getMessage(
                'aum_aa_create_accounts_total',
              )})`}
        </TextShiftedUp>
        <MediumMarginDiv />
      </LayoutTDBody>
    </>
  );
};

export default SelectedMarketplacesTextComponent;
