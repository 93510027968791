import React from 'react';
import { Button, Card, Text } from '@amzn/storm-ui';
import useIntl from 'src/shared/i18n/useIntl';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/shared/state/store';
import { setIsOverrideBusinessEmail } from 'src/shared/state/registration-slice';
import * as registerWithBusinessEmailConstants from 'src/pages/registration/components/constants';
import {
  RegistrationSwitchEmailViewCardButtonsWrapper,
  RegistrationSwitchEmailViewCardContent,
  RegistrationSwitchEmailViewCardContentWrapper,
  RegistrationSwitchEmailViewCardWrapper,
  RegistrationSwitchEmailViewWrapper,
  TextWithMargin,
} from 'src/pages/registration/style';

interface RegistrationSwitchEmailViewProps {
  email: string;
  switchAccountURL: string;
}

const RegistrationSwitchEmailView = ({
  email,
  switchAccountURL,
}: RegistrationSwitchEmailViewProps) => {
  const { intl } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <RegistrationSwitchEmailViewWrapper>
      <RegistrationSwitchEmailViewCardWrapper>
        <Card padding="xxlarge">
          <RegistrationSwitchEmailViewCardContentWrapper>
            <Text type="h2">
              {intl.getMessage(
                registerWithBusinessEmailConstants.REGISTER_WITH_BUSINESS_EMAIL_CARD_TITLE_TRANSLATION_KEY,
              )}
            </Text>
            <RegistrationSwitchEmailViewCardContent>
              <Text>
                {intl.getMessage(
                  registerWithBusinessEmailConstants.REGISTER_WITH_BUSINESS_EMAIL_CARD_TEXT_TRANSLATION_KEY,
                )}
              </Text>
              <Text>
                {intl.getMessage(
                  registerWithBusinessEmailConstants.REGISTER_WITH_BUSINESS_EMAIL_CARD_SIGNED_IN_TEXT_TRANSLATION_KEY,
                )}
                <TextWithMargin type="span">
                  <strong>{email}</strong>
                </TextWithMargin>
              </Text>
            </RegistrationSwitchEmailViewCardContent>
            <RegistrationSwitchEmailViewCardButtonsWrapper>
              <Button
                primary
                onClick={() => window.location.assign(switchAccountURL)}
              >
                {intl.getMessage(
                  registerWithBusinessEmailConstants.REGISTER_WITH_BUSINESS_EMAIL_SWITCH_ACCOUNT_BUTTON_TRANSLATION_KEY,
                )}
              </Button>
              <Button
                onClick={() => dispatch(setIsOverrideBusinessEmail(true))}
              >
                {intl.getMessage(
                  registerWithBusinessEmailConstants.REGISTER_WITH_BUSINESS_EMAIL_CONTINUE_BUTTON_TRANSLATION_KEY,
                )}
              </Button>
            </RegistrationSwitchEmailViewCardButtonsWrapper>
          </RegistrationSwitchEmailViewCardContentWrapper>
        </Card>
      </RegistrationSwitchEmailViewCardWrapper>
    </RegistrationSwitchEmailViewWrapper>
  );
};

export default RegistrationSwitchEmailView;
