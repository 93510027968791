import React, { FC } from 'react';
import styled from 'styled-components';
import { MessageCallout } from '@amzn/storm-ui';

const BannerRow = styled.div`
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing.xlarge} auto;
`;

type ErrorMessageProps = {
  message: boolean | string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ message, ...rest }) => {
  if (!message) {
    return null;
  }

  return (
    <MessageCallout
      type="error"
      className="error"
      data-message={message}
      {...rest}
    >
      {message}
    </MessageCallout>
  );
};

type BannerMessageProps = {
  message: boolean | string;
};

const BannerMessage: FC<BannerMessageProps> = ({ message, ...rest }) => {
  if (!message) {
    return null;
  }

  return (
    <MessageCallout
      type="success"
      className="success"
      data-message={message}
      {...rest}
    >
      {message}
    </MessageCallout>
  );
};

type BannerSectionProps = {
  error?: ErrorMessageProps;
  banner?: BannerMessageProps;
};

const BannerSection: FC<BannerSectionProps> = ({ error, banner }) => (
  <BannerRow className="banner">
    {error && <ErrorMessage {...error} />}
    {banner && <BannerMessage {...banner} />}
  </BannerRow>
);

export default BannerSection;
