import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html{
        height: 100%;
        width:100%
    }
    body {
        height: 100%;
    }
    #app {
        width: 100%;
        height: 100%;
    }
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    main {
      width: 100%;
      height: 100%;
      padding: 24px
    }
`;

export default GlobalStyle;
