import { useMemo } from 'react';
import { SellingAccount } from '@models/account';
import { useQuerySellingAccountQuery } from '@services/account/account';
import { LocationOption, useGetCountriesQuery } from '@services/address';

const useGetSellingAccounts = (): {
  sellingAccounts?: SellingAccount[];
  countryList?: LocationOption[];
  isLoading: boolean;
  isError: boolean;
} => {
  const countryList = useGetCountriesQuery();
  // Call query selling accounts without any filter.
  const sellingAccountsResponse = useQuerySellingAccountQuery({});

  const sellingAccounts = useMemo(() => {
    return sellingAccountsResponse.data?.sellingAccounts;
  }, [sellingAccountsResponse.data]);

  return {
    sellingAccounts,
    countryList: countryList.data,
    isLoading: countryList.isLoading || sellingAccountsResponse.isLoading,
    isError: sellingAccountsResponse.isError || sellingAccountsResponse.isError,
  };
};

export default useGetSellingAccounts;
