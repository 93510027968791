import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  APIServedResource,
  ApiResourceState,
  bffApi,
  containsClientSideErrorCode,
  putWithCsrf,
} from '../../shared/api';
import { AxiosResponse } from 'axios';
import { RootState } from '../../shared/state/store';

export type TermsState = {
  terms: APIServedResource<string>;
  termsChanges: APIServedResource<string>;
  termsForToken: APIServedResource<string>;
  acceptTokenStatus: APIServedResource<string>;
  errorCode: string | undefined;
};

export const TERMS_SLICE_NAME = 'terms';

const initialState: TermsState = {
  terms: { status: ApiResourceState.IDLE },
  termsChanges: { status: ApiResourceState.IDLE },
  termsForToken: { status: ApiResourceState.IDLE },
  acceptTokenStatus: { status: ApiResourceState.IDLE },
  errorCode: undefined,
};

export const marketplaceExpansionSlice = createSlice({
  name: TERMS_SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTerms.pending, (state) => {
        state.terms.status = ApiResourceState.LOADING;
      })
      .addCase(fetchTerms.fulfilled, (state, action) => {
        state.terms = {
          status: ApiResourceState.SUCCEEDED,
          value: action.payload,
        };
      })
      .addCase(fetchTerms.rejected, (state) => {
        state.terms.status = ApiResourceState.FAILED;
      })
      .addCase(fetchTermsChanges.pending, (state) => {
        state.termsChanges.status = ApiResourceState.LOADING;
      })
      .addCase(fetchTermsChanges.fulfilled, (state, action) => {
        state.termsChanges = {
          status: ApiResourceState.SUCCEEDED,
          value: action.payload,
        };
      })
      .addCase(fetchTermsChanges.rejected, (state) => {
        state.termsChanges.status = ApiResourceState.FAILED;
      })
      .addCase(fetchTermsForToken.pending, (state) => {
        state.termsForToken.status = ApiResourceState.LOADING;
      })
      .addCase(fetchTermsForToken.fulfilled, (state, action) => {
        state.termsForToken = {
          status: ApiResourceState.SUCCEEDED,
          value: action.payload,
        };
      })
      .addCase(fetchTermsForToken.rejected, (state, action) => {
        state.termsForToken.status = ApiResourceState.FAILED;
        if (action.payload) {
          state.errorCode = action.payload.errorCode;
        }
      })
      .addCase(acceptToken.pending, (state) => {
        state.acceptTokenStatus.status = ApiResourceState.LOADING;
      })
      .addCase(acceptToken.fulfilled, (state, action) => {
        state.acceptTokenStatus = {
          status: ApiResourceState.SUCCEEDED,
          value: action.payload,
        };
      })
      .addCase(acceptToken.rejected, (state) => {
        state.acceptTokenStatus.status = ApiResourceState.FAILED;
      });
  },
});

export const fetchTerms = createAsyncThunk(
  `${TERMS_SLICE_NAME}/fetchTerms`,
  async (): Promise<string> => {
    const response: AxiosResponse<string> = await bffApi.get(
      `/terms/api/v1/advertising`,
    );
    return response.data;
  },
);

export const fetchTermsChanges = createAsyncThunk(
  `${TERMS_SLICE_NAME}/fetchTermsChanges`,
  async (): Promise<string> => {
    const response: AxiosResponse<string> = await bffApi.get(
      `/terms/api/v1/advertising-changes`,
    );
    return response.data;
  },
);

type FetchTermsForTokenRequest = {
  token: string;
};

export const fetchTermsForToken = createAsyncThunk<
  string,
  FetchTermsForTokenRequest,
  {
    // Optional fields for defining thunkApi field types
    rejectValue: {
      errorCode: string;
    };
  }
>(
  `${TERMS_SLICE_NAME}/fetchTermsWithToken`,
  async ({ token }: FetchTermsForTokenRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<string> = await bffApi.get(
        `/terms/api/v1/advertising/token/${token}`,
      );
      return response.data;
    } catch (e) {
      if (containsClientSideErrorCode(e)) {
        return rejectWithValue({ errorCode: e.response.data.errorCode });
      }
      throw e;
    }
  },
);

export const acceptToken = createAsyncThunk(
  `${TERMS_SLICE_NAME}/acceptToken`,
  async (token: string): Promise<string> => {
    const response: AxiosResponse<string> = await putWithCsrf(
      `/terms/api/v1/accept/token/${token}`,
      {},
    );
    return response.data;
  },
);

export function selectTermsStatus(state: RootState): APIServedResource<string> {
  return state.terms.terms;
}

export function selectTermsChangesStatus(
  state: RootState,
): APIServedResource<string> {
  return state.terms.termsChanges;
}

export function selectTermsForTokenStatus(
  state: RootState,
): APIServedResource<string> {
  return state.terms.termsForToken;
}

export function selectAcceptTermsStatus(
  state: RootState,
): APIServedResource<string> {
  return state.terms.acceptTokenStatus;
}

export function selectErrorCode(state: RootState): string | undefined {
  return state.terms.errorCode;
}

export default marketplaceExpansionSlice.reducer;
