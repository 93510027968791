/**
 * Access and settings selectors
 */
export const ACCOUNT_SETTINGS_PAGE_ID = 'account-settings';
export const ACCOUNT_SETTINGS_CONFIRM_MODAL_ID =
  'account-settings-confirm-modal';
export const GENERAL_ERROR_BANNER_MESSAGE_KEY =
  'account_settings_general_error_message';
export const SUBMIT_FORM_ERROR_SNACKBAR_MESSAGE_KEY =
  'account_settings_form_update_error_message';
export const SUBMIT_FORM_SUCCESS_SNACKBAR_MESSAGE_KEY =
  'account_settings_form_update_success_message';

/**
 * Access and settings Translation keys
 */
export const FORM_EDIT_BUTTON_TRANSLATION_KEY = 'account_settings_edit_button';
export const CONFIRMATION_MODAL_HEADER_TRANSLATION_KEY =
  'account_settings_confirm_modal_header';
export const CONFIRMATION_MODAL_MESSAGE_TRANSLATION_KEY =
  'account_settings_confirm_modal_message';
export const CONFIRMATION_MODAL_CONFIRM_BUTTON_TRANSLATION_KEY =
  'account_settings_confirm_modal_confirm_button_text';
export const CONFIRMATION_MODAL_CANCEL_BUTTON_TRANSLATION_KEY =
  'account_settings_confirm_modal_cancel_button_text';

export const ACCOUNT_COUNTRY_CODES = [
  'US',
  'CA',
  'MX',
  'AT',
  'GB',
  'FR',
  'DE',
  'IT',
  'ES',
  'NL',
  'SE',
  'TR',
  'BE',
  'PL',
  'EG',
  'AE',
  'SA',
  'SG',
  'AU',
  'JP',
  'IN',
  'BR',
];
