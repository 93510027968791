import AACChromeApi, {
  ChromeConfiguration,
  PageHeader,
} from '@amzn/aac-chrome-api';
import React, { useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { selectChromeConfigurationType } from './state/redux';

export enum ChromeConfigurationType {
  MACreation = 'MACreation',
  None = 'None',
}

const getDefaultChrome = () => {
  const config = new ChromeConfiguration();
  const pageHeader = new PageHeader();
  config.setPageHeader(pageHeader);
  return config;
};

const resetChrome = () => {
  const config = getDefaultChrome();
  AACChromeApi.setChromeConfiguration(config);
};

const setChromePageOptions = (
  chromeConfigurationType: ChromeConfigurationType,
) => {
  if (chromeConfigurationType === ChromeConfigurationType.MACreation) {
    const config = getDefaultChrome();
    config.setPageLayoutType('FOCUS');
    AACChromeApi.setChromeConfiguration(config);
  } else {
    resetChrome();
  }
};

const ChromeProvider: FC<React.PropsWithChildren<NonNullable<unknown>>> = (
  props: React.PropsWithChildren<NonNullable<unknown>>,
) => {
  const chromeConfigurationType: ChromeConfigurationType = useSelector(
    selectChromeConfigurationType,
  );
  useEffect(() => {
    setChromePageOptions(chromeConfigurationType);
  }, [chromeConfigurationType]);

  return <>{props.children}</>;
};

export default ChromeProvider;
