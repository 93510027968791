const ENTITY_NAME_MAX_LENGTH = 190;
const PHONE_NUMBER_MAX_LENGTH = 20;
const NO_BLANK_SPACE_REGEX = /\S/;
const NUMERIC_VALIDATION_REGEX = /[0-9]/;
const EMAIL_VALIDATION_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;

const PUBLIC_API_IDENTIFIER_HEADER = 'publicApi';
const CONTENT_TYPE_HEADER = 'Content-Type';
const ACCEPT_HEADER = 'Accept';
const PREFER_HEADER = 'Prefer';

const AMAZON_ADVERTISING_API_ADVERTISER_ID_HEADER =
  'Amazon-Advertising-API-AdvertiserId';
const AMAZON_ADS_ACCOUNT_ID_HEADER = 'Amazon-Ads-AccountId';
const AMAZON_ADVERTISING_API_IS_IMPERSONATOR_HEADER =
  'Amazon-Advertising-API-IsImpersonator';
const AMAZON_ADVERTISING_API_CLIENT_ID_HEADER =
  'Amazon-Advertising-API-ClientId';
const AMAZON_ADVERTISING_API_CSRF_DATA_HEADER =
  'Amazon-Advertising-API-CSRF-Data';
const AMAZON_ADVERTISING_API_CSRF_TOKEN_HEADER =
  'Amazon-Advertising-API-CSRF-Token';
const AMAZON_ADVERTISING_API_MARKETPLACE_ID_HEADER =
  'Amazon-Advertising-API-MarketplaceId';
const X_AMZ_REQUEST_CSRF_TOKEN_HEADER = 'X-Amz-Request-Csrf-Token';
const AMAZON_ADVERTISING_API_IS_PORTAL_SERVER =
  'Amazon-Advertising-API-IsPortalServer';

/**
 * Translation keys
 */
const PAGINATION_ARIA_LABEL_FIRST_PAGE = 'pagination_aria_label_first_page';
const PAGINATION_ARIA_LABEL_PREV_PAGE = 'pagination_aria_label_prev_page';
const PAGINATION_ARIA_LABEL_NEXT_PAGE = 'pagination_aria_label_next_page';
const PAGINATION_ARIA_LABEL_LAST_PAGE = 'pagination_aria_label_last_page';
const EM_DASH = '—';

export {
  ENTITY_NAME_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  NO_BLANK_SPACE_REGEX,
  NUMERIC_VALIDATION_REGEX,
  EMAIL_VALIDATION_REGEX,
  CONTENT_TYPE_HEADER,
  ACCEPT_HEADER,
  PREFER_HEADER,
  PUBLIC_API_IDENTIFIER_HEADER,
  AMAZON_ADVERTISING_API_ADVERTISER_ID_HEADER,
  AMAZON_ADS_ACCOUNT_ID_HEADER,
  AMAZON_ADVERTISING_API_IS_IMPERSONATOR_HEADER,
  AMAZON_ADVERTISING_API_CLIENT_ID_HEADER,
  AMAZON_ADVERTISING_API_CSRF_DATA_HEADER,
  AMAZON_ADVERTISING_API_CSRF_TOKEN_HEADER,
  AMAZON_ADVERTISING_API_MARKETPLACE_ID_HEADER,
  X_AMZ_REQUEST_CSRF_TOKEN_HEADER,
  AMAZON_ADVERTISING_API_IS_PORTAL_SERVER,
  PAGINATION_ARIA_LABEL_FIRST_PAGE,
  PAGINATION_ARIA_LABEL_PREV_PAGE,
  PAGINATION_ARIA_LABEL_NEXT_PAGE,
  PAGINATION_ARIA_LABEL_LAST_PAGE,
  EM_DASH,
};
