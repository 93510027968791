import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitExtendAccount,
  selectApiErrorMessageId,
  selectGetGlobalAccountExpansionStatus,
  selectIsSubmitLoading,
} from '../redux';
import { FormValues } from '../model';
import { AppDispatch } from '../../../shared/state/store';
import {
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  useFormState,
} from 'react-hook-form';
import { ApiResourceState } from '../../../shared/api';
import {
  AcceptButtonRowContainer,
  ButtonContainer,
  AcceptTextContainer,
} from '../style';
import { Text, Button, TextButton, InlineMessage } from '@amzn/storm-ui';
import { useBundle } from '@amzn/react-arb-tools';

type SubmitComponentProps = {
  control: Control<FormValues>;
  handleSubmit: UseFormHandleSubmit<FormValues>;
};

const openTerms = () => {
  window.open('/terms', '_blank');
};
const redirectAccountManagement = () => {
  window.location.assign('/accounts');
};

const SubmitComponent: FC<SubmitComponentProps> = ({
  control,
  handleSubmit,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const selectedAccountForMarketplaceExpansion = searchParams.get(
    'selectedAccountForMarketplaceExpansion',
  );
  const redirectOrigin = searchParams.get('redirectOrigin');

  const apiErrorMessage = useSelector(selectApiErrorMessageId);
  const isSubmitLoading: boolean = useSelector(selectIsSubmitLoading);
  const getGlobalAccountExpansionStatus = useSelector(
    selectGetGlobalAccountExpansionStatus,
  );
  const showPageBody =
    getGlobalAccountExpansionStatus === ApiResourceState.SUCCEEDED;

  const { isValid } = useFormState({
    control: control,
  });

  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');
  if (selectedAccountForMarketplaceExpansion == null || isBundleLoading) {
    return null;
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    dispatch(
      submitExtendAccount({
        accountName: data.accountName,
        requesterName: '', //TODO for vendor
        requesterEmail: '', //TODO for vendor
        requesterCompanyName: '', //TODO for vendor
        requesterPhoneNumber: '', //TODO for vendor
        vendorApproverEmail: '', //TODO for vendor
        selectedAccountEntityId: selectedAccountForMarketplaceExpansion,
        marketplaceList: data.selectedMarketplaces
          .filter((m) => m.selected)
          .map((m) => m.obfuscatedMarketplaceId),
        redirectOrigin: redirectOrigin,
      }),
    );
  };

  return (
    <AcceptButtonRowContainer hidden={!showPageBody}>
      <AcceptTextContainer>
        <Text>
          {intl.formatMessage('aum_aa_create_agreement_text', {
            agreement: (
              <TextButton onClick={openTerms}>
                {intl.getMessage('aum_aa_create_advertising_agreement')}
              </TextButton>
            ),
          })}
        </Text>
      </AcceptTextContainer>

      <ButtonContainer>
        <TextButton
          // Note this is different from AUMS, but it makes sense to
          // redirect the user to their accounts and not new registration for MP expansion
          onClick={redirectAccountManagement}
          className="self-reg-cancel-button"
          disabled={isSubmitLoading}
        >
          {intl.getMessage('aum_aa_create_cancel')}
        </TextButton>

        <Button
          className="self-reg-submit-button"
          primary
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitLoading || !!apiErrorMessage || !isValid}
        >
          {isSubmitLoading ? (
            <InlineMessage
              messageType="loading"
              message={intl.getMessage('aac_aa_submit_button_loading')}
            />
          ) : (
            intl.getMessage('aum_aa_create_submit_button')
          )}
        </Button>
      </ButtonContainer>
    </AcceptButtonRowContainer>
  );
};

export default SubmitComponent;
