import { APIServedResource, ApiResourceState, bffApi } from '../api';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ChromeConfigurationType } from '../chrome';
import { AxiosResponse } from 'axios';

export type SharedState = {
  sauronContext: APIServedResource<SauronContext>;
  chromeConfigurationType: ChromeConfigurationType;
};

// can be extended to support all fields as needed
export type SauronContext = {
  customerInfo: CustomerInfo;
  localeInfo: LocaleInfo;
};

export type CustomerInfo = {
  loginCustomerId: string;
  marketplaceId: string;
};

export type LocaleInfo = {
  translationKeys: {
    locale: string;
  }[];
};

const initialState: SharedState = {
  sauronContext: { status: ApiResourceState.IDLE },
  chromeConfigurationType: ChromeConfigurationType.None,
};

export const SHARED_SLICE_NAME = 'shared';

export const sharedSlice = createSlice({
  name: SHARED_SLICE_NAME,
  initialState: initialState,
  reducers: {
    setChromeConfigurationType: (
      state,
      action: PayloadAction<ChromeConfigurationType>,
    ) => {
      state.chromeConfigurationType = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSauronContext.pending, (state) => {
        state.sauronContext.status = ApiResourceState.LOADING;
      })
      .addCase(fetchSauronContext.fulfilled, (state, action) => {
        state.sauronContext = {
          status: ApiResourceState.SUCCEEDED,
          value: action.payload,
        };
      })
      .addCase(fetchSauronContext.rejected, (state) => {
        state.sauronContext.status = ApiResourceState.FAILED;
      });
  },
});

export const fetchSauronContext = createAsyncThunk(
  `${SHARED_SLICE_NAME}/fetchSauron`,
  async (): Promise<SauronContext> => {
    const response: AxiosResponse<SauronContext> =
      // /terms is temporary since we only have this path launching as of 11/03/23
      await bffApi.get('/terms/api/v1/configs/sauron');
    return response.data;
  },
);

export function selectLocale(state: RootState): string | null {
  if (
    state.shared.sauronContext.status === ApiResourceState.SUCCEEDED &&
    state.shared.sauronContext.value.localeInfo.translationKeys.length > 0
  ) {
    return state.shared.sauronContext.value.localeInfo.translationKeys[0]
      .locale;
  }
  return null;
}

export function selectChromeConfigurationType(
  state: RootState,
): ChromeConfigurationType {
  return state.shared.chromeConfigurationType;
}

export const { setChromeConfigurationType } = sharedSlice.actions;

export default sharedSlice.reducer;
