import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../shared/state/store';
import { setChromeConfigurationType } from '../../shared/state/redux';
import { ChromeConfigurationType } from '../../shared/chrome';

const ManagerAccountRegistrationPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(setChromeConfigurationType(ChromeConfigurationType.MACreation));
  }, []);

  return (
    <>
      <h1>ManagerAccount Registration page</h1>
      <Link to="/registration/registration/create">back</Link>
    </>
  );
};

export default ManagerAccountRegistrationPage;
