import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomSnackbarProps } from '@components/notifications/customSnackbar/CustomSnackbar';
import { SnackbarTypes } from '@components/notifications/enums';
import {
  DEFAULT_ERROR_SNACKBAR_DURATION,
  DEFAULT_SNACKBAR_DURATION,
  SNACKBAR_ERROR_ID_SUFFIX,
  SNACKBAR_LOADING_ID_SUFFIX,
  SNACKBAR_SUCCESS_ID_SUFFIX,
  SNACKBAR_WARNING_ID_SUFFIX,
} from '@components/notifications/constants';

// Helper function to create standardized notification settings
const getNotificationSettings = (payload: CustomSnackbarProps) => {
  const snackbarTypesMap = new Map([
    [
      SnackbarTypes.Success,
      {
        suffix: SNACKBAR_SUCCESS_ID_SUFFIX,
        defaultDuration: DEFAULT_ERROR_SNACKBAR_DURATION,
      },
    ],
    [
      SnackbarTypes.Error,
      {
        suffix: SNACKBAR_ERROR_ID_SUFFIX,
        defaultDuration: DEFAULT_ERROR_SNACKBAR_DURATION,
      },
    ],
    [
      SnackbarTypes.Warning,
      {
        suffix: SNACKBAR_WARNING_ID_SUFFIX,
        defaultDuration: DEFAULT_SNACKBAR_DURATION,
      },
    ],
    [
      SnackbarTypes.Loading,
      {
        suffix: SNACKBAR_LOADING_ID_SUFFIX,
        defaultDuration: 0,
      },
    ],
  ]);

  const { suffix, defaultDuration } =
    snackbarTypesMap.get(payload?.type as SnackbarTypes) || {};

  return {
    ...payload,
    id: suffix ? `${payload.id}-${suffix}` : payload.id,
    duration:
      payload.duration !== undefined ? payload.duration : defaultDuration,
  };
};

interface NotificationState {
  notifications: Record<string, CustomSnackbarProps>;
}

const initialState: NotificationState = {
  notifications: {},
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<CustomSnackbarProps>) => {
      const updatedNotification = getNotificationSettings(action.payload);
      state.notifications[updatedNotification.id] = updatedNotification;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      delete state.notifications[id];
    },
    clearNotifications: (state) => {
      state.notifications = {};
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } =
  notificationSlice.actions;

export default notificationSlice.reducer;
