import React, { FC } from 'react';
import { Checkbox, useUID } from '@amzn/storm-ui';

type CheckboxWithIdProps = {
  idSuffix: string;
  label: React.ReactNode;
  [x: string]: any; // used to "type" ...rest
};
// need this wrapper to use useUID() in a loop or conditional
// see: https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
const CheckboxWithId: FC<CheckboxWithIdProps> = ({
  idSuffix,
  label,
  ...rest
}) => <Checkbox id={useUID(idSuffix)} label={label} {...rest} />;

export default CheckboxWithId;
