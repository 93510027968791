/**
 * Form validation Regex
 */
export const PHONE_VALIDATION =
  /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const WEBSITE_VALIDATION =
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(:[0-9]{1,5})?(\/[/\w .-]*)*\/?(\?[/\w .-]*=[/\w .-]*)?(#[/\w .-]*)?$/;
export const NUMBER_VALIDATION = /^-?\d+$/;
export const POSTAL_CODE_VALIDATION = /^[\sa-zA-Z0-9-]+$/;

/**
 * Form translation keys
 */
export const OPTIONAL_TAG_TRANSLATION_KEY = 'form_optional_tag';
export const SUBMIT_BUTTON_TRANSLATION_KEY = 'form_submit_button';
export const CANCEL_BUTTON_TRANSLATION_KEY = 'form_cancel_button';
export const REQUIRED_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY =
  'form_validation_required_error_message';
export const NUMBER_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY =
  'form_validation_number_error_message';
export const PHONE_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY =
  'form_validation_phone_error_message';
export const WEBSITE_URL_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY =
  'form_validation_website_error_message';
