import React from 'react';
import {
  Controller,
  FieldError,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import { InputFormGroup } from '@amzn/storm-ui';
import get from 'lodash/get';
import { CustomFormRow } from './style';

interface inputControllerProps {
  label: string;
  id: string;
  controllerName: string;
  labelTopSpacing?: string;
  helpTipText?: string;
  optionalTag?: string;
  registerOptions?: RegisterOptions;
  type?: string;
}

const InputController = ({
  label,
  id,
  controllerName,
  labelTopSpacing = '0px',
  helpTipText,
  optionalTag,
  registerOptions,
  type = 'text',
}: inputControllerProps) => {
  const {
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <CustomFormRow
      label={label}
      labelTopSpacing={labelTopSpacing}
      id={id}
      helpTipText={helpTipText}
      optionalTag={optionalTag}
      controlComponents={[
        (componentId: string) => (
          <Controller
            name={controllerName}
            control={control}
            render={({ field }) => (
              <>
                <InputFormGroup
                  {...register(controllerName, registerOptions)}
                  statusType={
                    (get(errors, controllerName) as FieldError)?.message
                      ? 'error'
                      : undefined
                  }
                  message={(get(errors, controllerName) as FieldError)?.message}
                  id={componentId}
                  fullWidth
                  onChange={(event) => {
                    setValue(controllerName, event.target.value);
                    trigger(controllerName);
                  }}
                  type={type}
                  value={field.value}
                />
              </>
            )}
          />
        ),
      ]}
    />
  );
};

export default InputController;
