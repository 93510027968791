import { useContext } from 'react';
import { BundleContext } from '@i18n/BundleProvider';
import { MessageBundle } from '@amzn/arb-tools';
import { useLocalizationContext } from '@amzn/react-arb-tools';

export const BUNDLE_ERROR = 'No default message bundle provided';
/**
 * Custom useIntl hook to get the message bundle from the ARB JS API.
 */
const useIntl = (): { intl: MessageBundle; locale: string } => {
  const intl = useContext(BundleContext);
  const { localizationContext } = useLocalizationContext();

  if (intl === undefined) {
    throw new Error(BUNDLE_ERROR);
  }

  return {
    intl,
    locale: localizationContext.getLocale(),
  };
};

export default useIntl;
