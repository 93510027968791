import React from 'react';
import { Link, Text, TextButton } from '@amzn/storm-ui';
import {
  REGISTRATION_USER_DETAILS_INFO_TRANSLATION_KEY,
  REGISTRATION_USER_DETAILS_LINK_TRANSLATION_KEY,
  REGISTRATION_USER_DETAILS_TITLE_TRANSLATION_KEY,
} from 'src/pages/registration/components/constants';
import { RegistrationFormCardHeaderInfoWrapper } from 'src/pages/registration/style';
import useIntl from '@i18n/useIntl';

interface RegistrationFormHeaderProps {
  userName: string;
  emailAddress: string;
  switchAccountUrl?: string;
}

const RegistrationUserDetails = ({
  userName,
  emailAddress,
  switchAccountUrl,
}: RegistrationFormHeaderProps) => {
  const { intl } = useIntl();
  return (
    <>
      <Text type="h2" fontSize="large">
        {`${intl.getMessage(
          REGISTRATION_USER_DETAILS_TITLE_TRANSLATION_KEY,
        )} ${userName}!`}
      </Text>
      <RegistrationFormCardHeaderInfoWrapper>
        <Text textColor="secondary">
          {`${intl.getMessage(
            REGISTRATION_USER_DETAILS_INFO_TRANSLATION_KEY,
          )} ${emailAddress}`}
        </Text>
        {switchAccountUrl && (
          <TextButton>
            <Link href={switchAccountUrl}>
              {intl.getMessage(REGISTRATION_USER_DETAILS_LINK_TRANSLATION_KEY)}
            </Link>
          </TextButton>
        )}
      </RegistrationFormCardHeaderInfoWrapper>
    </>
  );
};

export default RegistrationUserDetails;
