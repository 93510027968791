import React, { ReactElement, useCallback } from 'react';
import { Snackbar } from '@amzn/storm-ui';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { removeNotification } from 'src/shared/state/notifications-slice';
import useDuration from '@components/notifications/hook/useDuration';
import {
  SNACKBAR_CLASS_NAME,
  DEFAULT_SNACKBAR_DURATION,
} from '@components/notifications/constants';

export const SnackbarWrapper = styled.div`
  margin-block-start: ${({ theme }) => theme.spacing.small};
  :first-child {
    margin-block-start: 0;
  }
`;

export interface CustomSnackbarProps {
  id: string;
  type: string;
  message: string;
  duration?: number;
  customOnCloseCallback?: () => void;
}

const CustomSnackbar = ({
  id,
  type,
  message,
  duration = DEFAULT_SNACKBAR_DURATION,
  customOnCloseCallback,
}: CustomSnackbarProps): ReactElement | null => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeNotification(id));
    customOnCloseCallback && customOnCloseCallback();
  }, []);

  useDuration(duration, () => {
    onClose();
  });

  return (
    <SnackbarWrapper>
      <Snackbar
        id={id}
        className={SNACKBAR_CLASS_NAME}
        data-testid={id}
        type={type}
        onClose={onClose}
      >
        {message}
      </Snackbar>
    </SnackbarWrapper>
  );
};

export default CustomSnackbar;
