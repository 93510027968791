import React from 'react';
import { Tile, TileControl } from '@amzn/storm-ui';

export interface SellingAccountsTileProps {
  label: string;
  value: string;
  text: string;
  title: string;
  subText: string;
}

const SellingAccountsTile = ({
  label,
  value,
  text,
  title,
  subText,
}: SellingAccountsTileProps) => {
  return (
    <Tile label={label} value={value}>
      <TileControl
        titleFontSize="large"
        title={title}
        text={text}
        subText={subText}
      />
    </Tile>
  );
};

export default SellingAccountsTile;
