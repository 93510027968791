import React from 'react';
import { Icon, Text } from '@amzn/storm-ui';
import styled from 'styled-components';

export enum SpinnerSize {
  small = 'sm',
  medium = 'lg',
  large = '2x',
}

interface SpinnerProps {
  size?: SpinnerSize;
  text?: string;
  isFullScreen?: boolean;
  withBackground?: boolean;
}

const FullScreenSpinner = styled.div<{ withBackground?: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) =>
    props.withBackground ? 'rgba(255, 255, 255, 0.7)' : 'none'};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  i {
    margin-block-end: 10px;
  }
`;

const Spinner = ({
  size = SpinnerSize.large,
  text,
  isFullScreen = true,
  withBackground = false,
}: SpinnerProps) => {
  if (!isFullScreen) {
    return (
      <SpinnerWrapper role="alert" aria-live="assertive">
        <Icon type="spinner" size={size} aria-hidden="true" />
        {text && <Text type="h3">{text}</Text>}
      </SpinnerWrapper>
    );
  }

  return (
    <FullScreenSpinner
      role="alert"
      aria-live="assertive"
      withBackground={withBackground}
    >
      <SpinnerWrapper>
        <Icon type="spinner" size={size} aria-hidden="true" />
        {text && <Text type="h3">{text}</Text>}
      </SpinnerWrapper>
    </FullScreenSpinner>
  );
};

export default Spinner;
