import React, { ReactNode } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { Dropdown, DropdownItem } from '@amzn/storm-ui';
import { CustomFormRow } from './style';

type DropdownControllerItem = {
  value: string;
  label: ReactNode;
};

export interface DropdownControllerProps {
  label: string;
  overrideDropdownLabel: string;
  id: string;
  controllerName: string;
  items: DropdownControllerItem[];
  labelTopSpacing?: string;
  optionalTag?: string;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
  isLoading?: boolean;
  callback?: () => void;
  className?: string;
  onOverrideLabel?: (
    currentLabel: string,
    selectedValue: unknown,
    overrideDropdownLabel: string,
  ) => string;
}

const OverrideLabel = (
  currentLabel: string,
  selectedValue: unknown,
  overrideDropdownLabel: string,
) => {
  if (currentLabel) {
    return currentLabel;
  }
  return overrideDropdownLabel;
};

const DropdownController = ({
  label,
  overrideDropdownLabel,
  labelTopSpacing = '0px',
  id,
  controllerName,
  items = [],
  registerOptions,
  isLoading,
  disabled = false,
  className = '',
  onOverrideLabel = OverrideLabel,
  callback,
}: DropdownControllerProps) => {
  const { control, register, setValue, trigger } = useFormContext();
  const dropdownChangedHandler = async (value: string) => {
    setValue(controllerName, value);
    //Trigger the form validation
    await trigger(controllerName);
    callback && callback();
  };

  return (
    <CustomFormRow
      label={label}
      labelTopSpacing={labelTopSpacing}
      id={id}
      className={className}
      controlComponents={[
        (componentId: string) => (
          <Controller
            name={controllerName}
            control={control}
            render={({ field }) => (
              <Dropdown
                {...register(controllerName, registerOptions)}
                id={componentId}
                data-testid={componentId}
                onOverrideLabel={(currentLabel, selectedValue) =>
                  onOverrideLabel(
                    currentLabel,
                    selectedValue,
                    overrideDropdownLabel,
                  )
                }
                loading={isLoading}
                selectedValue={field.value}
                aria-describedby={`${id}-description`}
                onChange={dropdownChangedHandler}
                disabled={disabled}
              >
                {items.map((item: DropdownControllerItem) => (
                  <DropdownItem key={item.value} value={item.value}>
                    {item.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          />
        ),
      ]}
    />
  );
};

export default DropdownController;
