import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/shared/state/store';
import CustomSnackbar, {
  CustomSnackbarProps,
} from '@components/notifications/customSnackbar/CustomSnackbar';
import styled from 'styled-components';

export const SnackbarItemsWrapper = styled.div`
  position: fixed;
  right: ${({ theme }) => theme.spacing.large};
  bottom: ${({ theme }) => theme.spacing.large};
  width: 370px;
  z-index: 2;
`;
const SnackbarNotifications = () => {
  const snackbars = useSelector(
    (state: RootState) => state.notification.notifications,
  );
  const portalRef = useRef<HTMLElement>(document.getElementById('portal')!);

  return (
    <>
      {createPortal(
        Object.values(snackbars).length > 0 && (
          <SnackbarItemsWrapper>
            {Object.values(snackbars).map((snackbar: CustomSnackbarProps) => (
              <CustomSnackbar key={snackbar.id} {...snackbar} />
            ))}
          </SnackbarItemsWrapper>
        ),
        portalRef.current,
      )}
    </>
  );
};

export default SnackbarNotifications;
