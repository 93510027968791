import React, { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './shared/router';
import GlobalStyle from 'src/global-style';
import SnackbarNotifications from '@components/notifications/customSnackbar/SnackbarNotifications';

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <main>
        <RouterProvider router={router} />
        <SnackbarNotifications />
      </main>
    </>
  );
};

export default App;
