import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BasePagination,
  Checkbox,
  PaginationFirstButton,
  PaginationLastButton,
  PaginationNavGroup,
  PaginationNextButton,
  PaginationPageStatus,
  PaginationPrevButton,
  TileGroup,
} from '@amzn/storm-ui';
import { useFormContext } from 'react-hook-form';
import { SellingAccount } from '@models/account';
import useIntl from '@i18n/useIntl';
import {
  setIsBusinessDetailsFailed,
  setIsBusinessDetailsLoading,
  setIsBusinessDetailsValid,
} from 'src/shared/state/registration-slice';
import {
  formatCountryCodesWithPipe,
  getSellingAccountType,
} from '@components/form/account/utils';
import SellingAccountsTile from 'src/pages/registration/components/SellingAccounts/SellingAccountTile';
import * as sellingAccountConstants from 'src/pages/registration/components/SellingAccounts/constants';
import { SELLING_ACCOUNTS_CONTROLLER_GROUP } from '@components/form/account/constants';
import { SELLING_ACCOUNT_TILE_GROUP_ID } from 'src/pages/registration/components/constants';
import {
  SellingAccountPagination,
  SellingAccountTileCheckboxWrapper,
} from 'src/pages/registration/style';
import { ACCOUNT_REGISTRATION_PAGE_ID } from 'src/pages/registration/constants';
import {
  PAGINATION_ARIA_LABEL_FIRST_PAGE,
  PAGINATION_ARIA_LABEL_LAST_PAGE,
  PAGINATION_ARIA_LABEL_NEXT_PAGE,
  PAGINATION_ARIA_LABEL_PREV_PAGE,
} from 'src/shared/constants';
import { logEvent } from '@utils/takt';
import { LocationOption } from 'src/shared/services/address';
import isEmpty from 'lodash/isEmpty';
import useUpdateSelectedSellingAccount from '../../hooks/useUpdateSelectedSellingAccount';
import size from 'lodash/size';

interface SellingAccountsTilesProps {
  sellingAccounts: SellingAccount[];
  countryList: LocationOption[];
}

const SellingAccountsTiles = ({
  sellingAccounts,
  countryList,
}: SellingAccountsTilesProps) => {
  const { intl } = useIntl();
  const [currentPage, setCurrentPage] = useState(0);

  const [checked, setChecked] = useState(false);
  const {
    setValue,
    formState: { defaultValues },
    reset,
  } = useFormContext();

  const { updateSelectedSellingAccount } = useUpdateSelectedSellingAccount();

  useEffect(() => {
    setValue(SELLING_ACCOUNTS_CONTROLLER_GROUP, sellingAccounts);
  }, [sellingAccounts]);

  const totalItems = sellingAccounts.length;
  const totalPages = Math.ceil(
    totalItems / sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_SIZE,
  );

  const currentAccounts = sellingAccounts.slice(
    currentPage * sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_SIZE,
    (currentPage + 1) * sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_SIZE,
  );

  const [selectedSellingAccountId, setSelectedSellingAccountId] =
    useState<string>('');
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      setSelectedSellingAccountId('');

      reset({
        ...defaultValues,
        sellingAccounts: event.target.checked ? [] : sellingAccounts,
      });

      dispatch(setIsBusinessDetailsValid(false));

      // trigger log event only when the user disable the selling accounts.
      if (event.target.checked) {
        logEvent(
          `${ACCOUNT_REGISTRATION_PAGE_ID}-${sellingAccountConstants.DISABLE_SELLING_ACCOUNT_CHECKBOX}`,
        );
      }
    },
    [setChecked, setSelectedSellingAccountId, defaultValues, sellingAccounts],
  );

  const getSellingAccountById = (id: string) =>
    sellingAccounts.find((account) => account.sellingAccountLinkToken == id);

  const onSellingAccountClicked = async ([sellingAccountId]: string[]) => {
    dispatch(setIsBusinessDetailsLoading(true));
    dispatch(setIsBusinessDetailsFailed(false));
    setSelectedSellingAccountId(sellingAccountId);
    const sellingAccountInfo = getSellingAccountById(sellingAccountId);

    if (isEmpty(sellingAccountInfo)) {
      return;
    }

    updateSelectedSellingAccount({
      sellingAccountLinkToken: sellingAccountInfo.sellingAccountLinkToken,
      sellingProgram: sellingAccountInfo.sellingProgram,
    });
  };

  return (
    <>
      <SellingAccountTileCheckboxWrapper>
        <Checkbox
          id={`${ACCOUNT_REGISTRATION_PAGE_ID}-${sellingAccountConstants.DISABLE_SELLING_ACCOUNT_CHECKBOX}`}
          label={intl.getMessage(
            sellingAccountConstants.SELLING_ACCOUNT_DISABLE_CHECKBOX_LABEL_TRANSLATION_KEY,
          )}
          checked={checked}
          onChange={handleChange}
        />
      </SellingAccountTileCheckboxWrapper>
      {!checked && (
        <>
          <TileGroup
            data-testid={SELLING_ACCOUNT_TILE_GROUP_ID}
            onChange={onSellingAccountClicked}
            selectedValues={[selectedSellingAccountId]}
            name={sellingAccountConstants.TILE_GROUP_NAME}
            multiple={false}
            numColumns={1}
          >
            {currentAccounts.map((account) => (
              <SellingAccountsTile
                key={account.sellingAccountLinkToken}
                label={account.sellingAccountLinkToken}
                value={account.sellingAccountLinkToken}
                text={getSellingAccountType(intl, account.sellingProgram) || ''}
                title={account.displayName}
                subText={formatCountryCodesWithPipe(
                  countryList,
                  account.countryCodes,
                )}
              />
            ))}
          </TileGroup>

          {size(sellingAccounts) >
            sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_SIZE && (
            <SellingAccountPagination>
              <BasePagination>
                <PaginationPageStatus
                  id={sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_STATUS}
                  currentPageLabel={`${
                    currentPage *
                      sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_SIZE +
                    1
                  } - ${Math.min(
                    (currentPage + 1) *
                      sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_SIZE,
                    totalItems,
                  )} ${intl.formatMessage(
                    sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_TOTAL_ITEMS_TRANSLATION_KEY,
                    {
                      totalItems: totalItems,
                    },
                  )}`}
                />
                <PaginationNavGroup
                  aria-controls={
                    sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_STATUS
                  }
                  aria-labelledby={
                    sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_STATUS
                  }
                >
                  <PaginationFirstButton
                    id={
                      sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_FIRST_BUTTON
                    }
                    ariaLabel={PAGINATION_ARIA_LABEL_FIRST_PAGE}
                    onClick={() => setCurrentPage(0)}
                    disabled={currentPage === 0 || totalPages === 0}
                  />
                  <PaginationPrevButton
                    id={
                      sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_PREV_BUTTON
                    }
                    ariaLabel={PAGINATION_ARIA_LABEL_PREV_PAGE}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 0 || totalPages === 0}
                  />
                  <PaginationNextButton
                    id={
                      sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_NEXT_BUTTON
                    }
                    ariaLabel={PAGINATION_ARIA_LABEL_NEXT_PAGE}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      currentPage === totalPages - 1 || totalPages === 0
                    }
                  />
                  <PaginationLastButton
                    id={
                      sellingAccountConstants.SELLING_ACCOUNT_PAGINATION_LAST_BUTTON
                    }
                    ariaLabel={PAGINATION_ARIA_LABEL_LAST_PAGE}
                    onClick={() => setCurrentPage(totalPages - 1)}
                    disabled={
                      currentPage === totalPages - 1 || totalPages === 0
                    }
                  />
                </PaginationNavGroup>
              </BasePagination>
            </SellingAccountPagination>
          )}
        </>
      )}
    </>
  );
};

export default SellingAccountsTiles;
