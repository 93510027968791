import { useDispatch } from 'react-redux';
import { SellingAccount, SellingAccountType } from 'src/shared/models/account';
import { useLazyQuerySellingAccountQuery } from 'src/shared/services/account/account';
import {
  setIsBusinessDetailsFailed,
  setIsBusinessDetailsLoading,
  setIsBusinessDetailsValid,
  setIsSeller,
} from 'src/shared/state/registration-slice';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import { useFormContext } from 'react-hook-form';
import {
  ACCOUNT_NAME_FIELD_CONTROLLER_NAME,
  BUSINESS_DETAILS_CONTROLLER_GROUP,
  SELECTED_SELLING_ACCOUNT_ID,
} from 'src/shared/components/form/account/constants';
import { useCallback } from 'react';

const useUpdateSelectedSellingAccount = () => {
  const dispatch = useDispatch();
  const [querySellingAccounts] = useLazyQuerySellingAccountQuery();
  const { setValue, getValues, trigger, resetField } = useFormContext();

  const isBusinessDetailsValid = useCallback(
    async () => await trigger(BUSINESS_DETAILS_CONTROLLER_GROUP),
    [trigger],
  );

  const updateSelectedSellingAccount = async ({
    sellingAccountLinkToken,
    sellingProgram,
  }: {
    sellingAccountLinkToken: string;
    sellingProgram: SellingAccountType;
  }) => {
    // Call query selling accounts with filters.
    const { data, isError } = await querySellingAccounts({
      sellingAccountLinkToken,
      sellingProgram,
    });
    const selectedSellingAccount = get(data, [
      'sellingAccounts',
      0,
    ]) as SellingAccount;
    dispatch(setIsBusinessDetailsLoading(false));
    dispatch(setIsBusinessDetailsFailed(isError));
    dispatch(setIsSeller(isEqual(sellingProgram, 'AMAZON_SELLER')));

    setValue(SELECTED_SELLING_ACCOUNT_ID, sellingAccountLinkToken);
    if (
      selectedSellingAccount &&
      selectedSellingAccount.displayName &&
      !isEqual(sellingProgram, 'AMAZON_AUTHOR')
    ) {
      setValue(
        ACCOUNT_NAME_FIELD_CONTROLLER_NAME,
        selectedSellingAccount.displayName,
      );
      trigger(ACCOUNT_NAME_FIELD_CONTROLLER_NAME);
    }

    if (
      selectedSellingAccount &&
      selectedSellingAccount.business &&
      !isEmpty(selectedSellingAccount.business.addresses)
    ) {
      const businessName =
        size(selectedSellingAccount.business.addresses) > 1
          ? selectedSellingAccount.displayName
          : get(selectedSellingAccount.business.addresses, [0, 'businessName']);
      setValue(BUSINESS_DETAILS_CONTROLLER_GROUP, {
        ...selectedSellingAccount.business,
        businessName,
      });
      const {
        business: { selectedAddressId },
      } = getValues();
      const isValid = await isBusinessDetailsValid();
      dispatch(setIsBusinessDetailsValid(selectedAddressId ? isValid : true));
    } else {
      resetField(BUSINESS_DETAILS_CONTROLLER_GROUP);
      dispatch(setIsBusinessDetailsValid(false));
    }
  };

  return { updateSelectedSellingAccount };
};

export default useUpdateSelectedSellingAccount;
