import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AccountDetailsForm from 'src/pages/accountSettings/components/AccountDetails/AccountDetailsForm';
import AccountDetailsViewer from 'src/pages/accountSettings/components/AccountDetails/AccountDetailsViewer';
import { RootState } from 'src/shared/state/store';

interface AccountDetailsProps {
  displayName: string;
  countries: string[];
}
const AccountDetails = ({
  displayName,
  countries = [],
}: AccountDetailsProps) => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      displayName,
      countries,
    },
  });

  /**
   * Update the default value when the form is submitted
   */
  useEffect(() => {
    methods.reset({
      displayName: displayName,
      countries: countries,
    });
  }, [displayName, countries]);

  const isEditMode = useSelector(
    (state: RootState) => state.accountSettings.accountInfoEditMode,
  );

  return (
    <FormProvider {...methods}>
      {isEditMode ? <AccountDetailsForm /> : <AccountDetailsViewer />}
    </FormProvider>
  );
};

export default AccountDetails;
