import React, { FC } from 'react';
import { selectApiErrorMessageId } from '../redux';
import BannerSection from '../../../shared/components/BannerSection';
import { useSelector } from 'react-redux';
import { useBundle } from '@amzn/react-arb-tools';

const ApiErrorMessage: FC = () => {
  const apiErrorMessageId = useSelector(selectApiErrorMessageId);
  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');
  if (isBundleLoading) {
    return null;
  }

  return (
    <BannerSection
      error={{
        message: !apiErrorMessageId
          ? false
          : intl.getMessage(apiErrorMessageId),
      }}
    />
  );
};

export default ApiErrorMessage;
