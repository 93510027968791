import React, { FC } from 'react';
import { Controller, Control, useFormState } from 'react-hook-form';
import { HelpTip, useUID } from '@amzn/storm-ui';
import { useSelector } from 'react-redux';
import {
  selectCurrentGlobalAccountName,
  selectIsSubmitLoading,
} from '../redux';
import { ENTITY_NAME_MAX_LENGTH } from '../../../shared/constants';
import { FormValues } from '../model';
import {
  LayoutTDHeading,
  InputFieldHeader,
  LayoutTDBody,
  AccountNameInput,
} from '../style';
import { useBundle } from '@amzn/react-arb-tools';
import { IntlContext } from '../../../shared/intl';

type AccountInfoComponentProps = {
  control: Control<FormValues>;
};

// from "rules" in Controller
type ErrorType = 'required' | 'maxLength';

const accountNameInvalidStringIds = {
  required: { id: 'aum_aa_create_validation_account_name', args: undefined },
  maxLength: {
    id: 'aum_aa_create_validation_name_length',
    args: { limit: ENTITY_NAME_MAX_LENGTH },
  },
};

const getErrorMessage = (
  intl: IntlContext,
  errorType: string,
): string | undefined => {
  if (errorType in accountNameInvalidStringIds) {
    return intl.formatMessage(
      accountNameInvalidStringIds[errorType as ErrorType].id,
      accountNameInvalidStringIds[errorType as ErrorType].args,
    );
  }
  return undefined;
};

const AccountInfoComponent: FC<AccountInfoComponentProps> = ({ control }) => {
  const currentGlobalAccountName = useSelector(selectCurrentGlobalAccountName);
  const disableInput = useSelector(selectIsSubmitLoading);
  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');

  const { errors } = useFormState({
    control: control,
    name: 'accountName',
  });

  if (currentGlobalAccountName == null || isBundleLoading) {
    // data is not loaded yet
    return null;
  }

  return (
    <>
      <LayoutTDHeading>
        <InputFieldHeader type="h5">
          {intl.getMessage('aum_aa_create_account_name')}
          <HelpTip position="top">
            {intl.getMessage('aum_aa_create_tooltip_account_name')}
          </HelpTip>
        </InputFieldHeader>
      </LayoutTDHeading>
      <LayoutTDBody>
        <Controller
          control={control}
          name="accountName"
          defaultValue={currentGlobalAccountName}
          rules={{
            required: true,
            maxLength: ENTITY_NAME_MAX_LENGTH,
          }}
          render={({ field }) => (
            <AccountNameInput
              {...field}
              id={useUID('self-reg-account-name-input')}
              className="self-reg-account-name-input"
              label=""
              value={field.value}
              maxLength={ENTITY_NAME_MAX_LENGTH}
              disabled={disableInput}
              statusType={errors.accountName ? 'error' : undefined}
              message={
                errors.accountName
                  ? getErrorMessage(intl, errors.accountName.type)
                  : undefined
              }
            />
          )}
        />
      </LayoutTDBody>
    </>
  );
};

export default AccountInfoComponent;
