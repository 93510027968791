/**
 * Account Details
 * Constants related to account details forms, including labels, IDs, and controller names for each field.
 */

/**
 * Selectors
 */
export const SELLING_ACCOUNT_FIELD_ID = 'selling-account';
export const SELLING_ACCOUNT_LINK_FIELD_ID = 'selling-account-link';
export const SELLING_ACCOUNT_TYPE_ID = 'selling-account-type';
export const ACCOUNT_STATUS_FIELD_ID = 'status';
export const REGION_COUNTRY_DISPLAY = 'region-country-display';

/**
 * Selling accounts
 */
export const SELLING_ACCOUNT_FIELD_LABEL =
  'account_settings_form_selling_account_label';
export const SELLING_ACCOUNT_LINK =
  'account_settings_form_selling_accounts_link';
/**
 * Account Status
 */
export const ACCOUNT_STATUS_FIELD_LABEL = 'account_settings_form_status_label';
export const ACCOUNT_STATUS_CREATED =
  'account_settings_form_account_status_approved';
export const ACCOUNT_STATUS_PENDING =
  'account_settings_form_account_status_pending';
export const ACCOUNT_STATUS_DISABLED =
  'account_settings_form_account_status_disabled';
export const ACCOUNT_STATUS_PARTIALLY_CREATED =
  'account_settings_form_account_status_pending';
/**
 * Account Name (View)
 */
export const ACCOUNT_NAME_FIELD_VIEW_LABEL =
  'account_settings_form_account_name_view_label';

/**
 * Account Country codes (View)
 */
export const ACCOUNT_COUNTRY_CODES_FIELD_VIEW_LABEL =
  'account_settings_form_advertising_location_view_label';
export const ACCOUNT_COUNTRIES = 'account_settings_form_advertising_countries';
