import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useUpdateAccountMutation } from '@services/account/account';
import useIntl from '@i18n/useIntl';
import { Account } from '@models/account';
import { SnackbarTypes } from '@components/notifications/enums';
import { addNotification } from 'src/shared/state/notifications-slice';
import {
  ACCOUNT_SETTINGS_PAGE_ID,
  SUBMIT_FORM_ERROR_SNACKBAR_MESSAGE_KEY,
  SUBMIT_FORM_SUCCESS_SNACKBAR_MESSAGE_KEY,
} from 'src/pages/accountSettings/constants';

const useSubmit = (callback: ActionCreatorWithoutPayload) => {
  const { intl } = useIntl();
  const { entityId } = useParams();
  const dispatch = useDispatch();
  const [updateAccount, { isLoading, isSuccess, isError }] =
    useUpdateAccountMutation();

  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      callback && dispatch(callback());
      dispatch(
        addNotification({
          id: ACCOUNT_SETTINGS_PAGE_ID,
          message: intl.getMessage(SUBMIT_FORM_SUCCESS_SNACKBAR_MESSAGE_KEY),
          type: SnackbarTypes.Success,
        }),
      );
    }
  }, [isSuccess, dispatch, callback]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addNotification({
          id: ACCOUNT_SETTINGS_PAGE_ID,
          message: intl.getMessage(SUBMIT_FORM_ERROR_SNACKBAR_MESSAGE_KEY),
          type: SnackbarTypes.Error,
        }),
      );
    }
  }, [isError]);

  const onSubmit = async (data: Partial<Account>) => {
    await updateAccount({ entityId: entityId!, data });
  };

  const onReset = () => {
    reset();
    callback && dispatch(callback());
  };

  return {
    onSubmit,
    onReset,
    isLoading,
  };
};

export default useSubmit;
