import React from 'react';
import { Text } from '@amzn/storm-ui';
import { TextWithSeparator } from '@components/form/style';
import { BusinessAddress } from 'src/shared/models/account';

type BusinessAddressViewerProps = {
  businessAddress: BusinessAddress;
};

const BusinessAddressViewer = (props: BusinessAddressViewerProps) => {
  const { businessAddress } = props;

  return (
    <>
      <Text textColor="secondary">{businessAddress.addressLine1}</Text>
      {businessAddress.addressLine2 && (
        <Text textColor="secondary">{businessAddress.addressLine2}</Text>
      )}
      <>
        <TextWithSeparator type="span" textColor="secondary">
          {businessAddress.city}
        </TextWithSeparator>
        <TextWithSeparator type="span" textColor="secondary">
          {businessAddress.state}
        </TextWithSeparator>
        <Text type="span" textColor="secondary">
          {businessAddress.zipCode}
        </Text>
      </>
      <Text textColor="secondary">{businessAddress.countryCode}</Text>
    </>
  );
};

export default BusinessAddressViewer;
