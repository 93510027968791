export const logEvent = <T>(context: string, details?: T): void => {
  const logData: { context: string; details?: string } = {
    context,
    ...(details && { details: JSON.stringify(details) }),
  };

  if (window?.AmazonTakt) {
    window.AmazonTakt.logCustom(logData);
  }
};
