import { createApi } from '@reduxjs/toolkit/query/react';
import { DEFAULT_LOCALE } from '@i18n/utils';
import { convertLocale } from '@utils/global';
import axiosBaseQuery from 'src/shared/api/api';

export interface Configuration {
  loginCustomerId: string;
  marketplaceId: string;
  entityId: string;
  entityName: string;
  entityType: string;
  locale: string;
  weblabs: Record<string, string>;
  isSpoofer: boolean;
  clientId: string;
  publicApiUrl: string;
  globalAccountId: string;
}
const BASE_URL = '/ads-account/api/v1/';
const CONFIGURATION_ENDPOINT = 'configs';
const REDUCER_PATH = 'configurationApi';

/**
 * Using mock data until the configuration API is updated and the data is flattened.
 */
export const configurationApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getConfiguration: builder.query<Configuration, void>({
      query: () => ({ url: CONFIGURATION_ENDPOINT, method: 'GET' }),
      transformResponse: (response: Configuration): Configuration => {
        return {
          ...response,
          locale: convertLocale(response.locale) || DEFAULT_LOCALE,
        };
      },
    }),
  }),
});

export const { useGetConfigurationQuery } = configurationApi;
