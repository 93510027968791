import React, { FC, useEffect } from 'react';
import { AppDispatch } from '../../shared/state/store';
import { ChromeConfigurationType } from '../../shared/chrome';
import { setChromeConfigurationType } from '../../shared/state/redux';
import {
  fetchTerms,
  fetchTermsChanges,
  fetchTermsForToken,
  selectTermsStatus,
  selectTermsChangesStatus,
  selectTermsForTokenStatus,
  selectAcceptTermsStatus,
  selectErrorCode,
} from './redux';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@amzn/storm-ui';
import styled from 'styled-components';
import { ApiResourceState } from 'src/shared/api';
import BannerSection from 'src/shared/components/BannerSection';
import { useBundle } from '@amzn/react-arb-tools';

export type TermsDocument = 'terms' | 'termsChanges' | 'termsAgreement';

const termsPageConfig = {
  ['terms']: {
    selector: selectTermsStatus,
    fixedSize: true,
  },
  ['termsChanges']: {
    selector: selectTermsChangesStatus,
    fixedSize: true,
  },
  ['termsAgreement']: {
    selector: selectTermsForTokenStatus,
    fixedSize: false,
  },
};

const ApiResultDiv = styled.div`
  margin: auto;
  margin-block-start: ${({ theme }) => theme.spacing.xlarge};
`;

// Copy pasta from AdvertiserRegistration
// https://code.amazon.com/packages/AmazonClicksRegistrationAssets/blobs/mainline/--/terms/src/css/common.scss
const TermsDiv = styled.div`
  font-size: 13px;
  border: 0.076em solid #e7e7e7;

  border-radius: 0.615em;
  padding: 1.538em 1.969em;
  margin: 1.969em auto;

  h1 {
    margin: 0.615em 0;
  }

  h2 {
    font-size: 1.307em;
    font-weight: normal;
  }

  h2,
  p,
  ol,
  ul {
    margin-bottom: 1.784em;
    color: #111;
  }
`;
const TermsFixedWidthDiv = styled(TermsDiv)`
  width: 72.307em;
`;

const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const TermsFlexibleSizeDiv = styled(TermsDiv)`
  max-height: 1000px;
  min-height: 100px;
  height: 50vh;
  min-width: 200px;
  overflow-y: auto;
`;

type TermsPageProps = {
  termsDocument: TermsDocument;
  fetchParams?: {
    token: string;
  };
};

type TermsComponentProps = {
  termsDocument: TermsDocument;
};

type ApiResultResultBannerProps = {
  termsDocument: TermsDocument;
};

const ApiResultResultBanner: FC<ApiResultResultBannerProps> = ({
  termsDocument,
}) => {
  const selectorMethod = termsPageConfig[termsDocument].selector;
  const submitStatus = useSelector(selectAcceptTermsStatus);
  const terms = useSelector(selectorMethod);
  const errorCode = useSelector(selectErrorCode);
  const [intl, isBundleLoading] = useBundle('pages.terms');
  if (isBundleLoading) {
    return null;
  }
  const errorMessage =
    errorCode !== undefined
      ? intl.formatMessage('error4xx', { errorCode })
      : submitStatus.status === ApiResourceState.FAILED ||
        terms.status === ApiResourceState.FAILED
      ? intl.getMessage('error500')
      : undefined;
  return (
    <ApiResultDiv id="AcceptTermsResult">
      <BannerSection
        error={{
          message: errorMessage ? errorMessage : false,
        }}
        banner={{
          message:
            submitStatus.status === ApiResourceState.SUCCEEDED
              ? intl.getMessage('acceptTermsSucceeded')
              : false,
        }}
      />
    </ApiResultDiv>
  );
};

const TermsComponent: FC<TermsComponentProps> = ({ termsDocument }) => {
  const selectorMethod = termsPageConfig[termsDocument].selector;
  const useFixedSize = termsPageConfig[termsDocument].fixedSize;
  const terms = useSelector(selectorMethod);

  if (terms.status !== ApiResourceState.SUCCEEDED) {
    return null;
  }

  return (
    <div className="terms">
      <div className="content">
        <div dir="auto">
          {useFixedSize ? (
            <TermsFixedWidthDiv
              dangerouslySetInnerHTML={{ __html: terms.value }}
            />
          ) : (
            <TermsFlexibleSizeDiv
              dangerouslySetInnerHTML={{ __html: terms.value }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TermsTemplate: FC<TermsPageProps> = ({ termsDocument, fetchParams }) => {
  const selectorMethod = termsPageConfig[termsDocument].selector;

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    // Set Chrome Configuration on Page component
    dispatch(setChromeConfigurationType(ChromeConfigurationType.None));
    // Get all data needed by the Page and its child components
    if (termsDocument === 'terms') {
      dispatch(fetchTerms());
    } else if (termsDocument === 'termsChanges') {
      dispatch(fetchTermsChanges());
    } else if (
      termsDocument === 'termsAgreement' &&
      fetchParams !== undefined
    ) {
      dispatch(fetchTermsForToken(fetchParams));
    }
  }, [termsDocument]);
  const terms = useSelector(selectorMethod);
  if (
    terms.status === ApiResourceState.LOADING ||
    terms.status === ApiResourceState.IDLE
  ) {
    return (
      <SpinnerDiv>
        <Icon type="spinner" size="5x" />
      </SpinnerDiv>
    );
  }

  return (
    <>
      <ApiResultResultBanner termsDocument={termsDocument} />
      <TermsComponent termsDocument={termsDocument} />
    </>
  );
};

export default TermsTemplate;
