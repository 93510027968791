import { SellingAccount } from '@models/account';
import { Captcha } from '@models/captcha';

/** Enum **/
export enum InvitationStatusCode {
  Redeemed = '701',
  Expired = '702',
  Revoked = '703',
  UnavailableToCurrentUser = '704',
  Invalid = '705',
}

/** Interfaces **/
interface RegistrationInfo {
  userName: string;
  emailAddress: string;
  captcha: Partial<Captcha>;
}

export interface AccountRegistrationInfo extends RegistrationInfo {
  switchAccountUrl: string;
  sellingAccounts: SellingAccount[];
  businessEmail: boolean;
}

export interface RetailerAccountRegistrationInfo extends RegistrationInfo {
  retailerId: string;
  retailerName?: string;
}

export interface RetailerAccountRegistrationInfoError {
  data: {
    errorCode: InvitationStatusCode;
    data: RetailerAccountRegistrationInfoErrorData;
  };
}

export interface RetailerAccountRegistrationInfoErrorData {
  loginEmail: string;
}
