export enum AccountStatus {
  Pending = 'PENDING',
  Created = 'CREATED',
  Disabled = 'DISABLED',
  PartiallyCreated = 'PARTIALLY_CREATED',
}

export type SellingAccountType =
  | 'AMAZON_SELLER'
  | 'AMAZON_VENDOR'
  | 'AMAZON_AUTHOR';

export type BillToType = 'MANAGER_ACCOUNT' | 'ADVERTISER_ACCOUNT';

export interface Business {
  zipCode: string;
  phone: string;
  website: string;
  city: string;
  countryCode: string;
  businessName: string;
  addressLine1: string;
  addressLine2?: string;
  state: string;
  selectedAddressId?: string;
  businessRegistrationNumber?: string;
}

export interface SellingAccount {
  sellingAccountLinkToken: string;
  displayName: string;
  sellingProgram: SellingAccountType;
  countryCodes: string[];
  business?: BusinessInfo;
}

export interface SellingAccountsResponse {
  nextToken?: string;
  sellingAccounts: SellingAccount[];
}

export interface Account {
  displayName: string;
  adsAccountId: string;
  status: AccountStatus;
  sellingAccounts?: SellingAccount[];
  business: Business;
  retailerId?: string;
  sellingAccountId?: string;
}

export interface BusinessAddress {
  addressToken: string;
  state: string;
  city: string;
  zipCode: string;
  countryCode: string;
  addressLine1: string;
  businessName: string;
  addressLine2?: string;
  phoneNumber?: string;
}

// New business type to be used for registration.
export interface BusinessInfo {
  website: string;
  addresses: BusinessAddress[];
}
