import styled from 'styled-components';
import { Card, Divider } from '@amzn/storm-ui';

export const AccountSettingsCard = styled(Card)`
  margin-block-end: ${({ theme }) => theme.spacing.large};
  :last-child {
    margin: 0;
  }
`;

export const AccountSettingsForm = styled.form`
  display: flex;
`;

export const AccountSettingsCardContent = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
`;

export const AccountSettingsDivider = styled(Divider)`
  margin: 0;
`;

export const SellingAccountWrapper = styled.div`
  span:first-child {
    margin-inline-end: ${({ theme }) => theme.spacing.mini};
  }
`;
