import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../shared/state/store';
import { setChromeConfigurationType } from '../../shared/state/redux';
import { ChromeConfigurationType } from '../../shared/chrome';
import {
  fetchGlobalAccountInfo,
  selectDoRedirectToAccountManagement,
  selectGetGlobalAccountExpansionStatus,
} from './redux';
import { FormValues } from './model';
import ApiErrorMessage from './components/apiErrorMessage';
import MarketplacesComponent from './components/marketplaces';
import MarketplacesErrorsComponent from './components/marketplacesErrors';
import SelectedMarketplacesText from './components/selectedMarketplacesText';
import AccountInfoComponent from './components/accountInfo';
import SubmitComponent from './components/submit';
import { useForm } from 'react-hook-form';
import {
  CenteredBodyDiv,
  XLargePaddingTopDiv,
  XLargeMarginDiv,
  MediumMarginDiv,
  PageTitle,
  PageLoadingSpinnerDiv,
  LayoutTable,
  LayoutTD,
  LargeCard,
  MarketplaceExpansion,
} from './style';
import { Icon, Text, CardHeader } from '@amzn/storm-ui';
import { ApiResourceState } from '../../shared/api';
import { useBundle } from '@amzn/react-arb-tools';
import { IntlContext } from 'src/shared/intl';

const redirectSuccessExtendAccount = (intl: IntlContext) => {
  window.location.assign(
    '/accounts?registrationMessageType=success' +
      `&registrationMessageHeading=${encodeURIComponent(
        intl.getMessage('aum_aa_create_extend_success_header'),
      )}&registrationMessageDetail=${encodeURIComponent(
        intl.getMessage('aum_aa_create_extend_success_detail'),
      )}`,
  );
};

const MarketplaceExpansionPage: FC = () => {
  const [searchParams] = useSearchParams();
  const selectedAccountForMarketplaceExpansion = searchParams.get(
    'selectedAccountForMarketplaceExpansion',
  );
  if (selectedAccountForMarketplaceExpansion == null) {
    return null;
  }
  const { handleSubmit, control, setValue, getValues, trigger, watch } =
    useForm<FormValues>({
      mode: 'all',
      defaultValues: {
        selectedMarketplaces: [],
      },
    });
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    // Set Chrome Configuration on Page component
    dispatch(setChromeConfigurationType(ChromeConfigurationType.None));
    // Get all data needed by the Page and its child components
    dispatch(fetchGlobalAccountInfo(selectedAccountForMarketplaceExpansion));
  }, []);

  // Try to have as little state in the root page as possible

  // This state handles loading the entire page so OK to leave
  const getGlobalAccountExpansionStatus = useSelector(
    selectGetGlobalAccountExpansionStatus,
  );
  const showPageBody =
    getGlobalAccountExpansionStatus === ApiResourceState.SUCCEEDED;

  // This state handles successful registration so litle effect on the performance of the entire registration flow
  const doRedirectToAccountManagement = useSelector(
    selectDoRedirectToAccountManagement,
  );

  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');
  if (isBundleLoading) {
    return null;
  }

  if (doRedirectToAccountManagement) {
    redirectSuccessExtendAccount(intl);
  }

  return (
    <MarketplaceExpansion>
      <CenteredBodyDiv>
        <ApiErrorMessage />
        <XLargePaddingTopDiv />

        <PageTitle type="h1">
          {intl.getMessage('aum_aa_create_create_an_aa')}
        </PageTitle>

        <XLargeMarginDiv />

        <PageLoadingSpinnerDiv hidden={showPageBody}>
          <Icon type="spinner" size="5x" />
        </PageLoadingSpinnerDiv>
        <div hidden={!showPageBody}>
          <LargeCard>
            <CardHeader>
              <Text type="h4">
                {intl.getMessage('aum_aa_create_account_details')}
              </Text>
            </CardHeader>
            <LayoutTable>
              <tbody>
                <tr>
                  <AccountInfoComponent {...{ control }} />
                </tr>
                <tr>
                  <LayoutTD>
                    <MediumMarginDiv />
                  </LayoutTD>
                </tr>
                <tr>
                  <MarketplacesComponent
                    {...{ control, setValue, getValues, watch, trigger }}
                  />
                </tr>
                <MarketplacesErrorsComponent {...{ control }} />
                <tr hidden={!showPageBody}>
                  <SelectedMarketplacesText {...{ watch }} />
                </tr>
                {/* This is needed for vendor marketplace expansion 
              
              <tr hidden={model.globalAccountExpansionInfo.registrationType === 'OpenRegistration'}>
                <LayoutTDHeading>
                  <InputFieldHeader type="h5">
                    {model.intl.formatMessage(i18n.contactInfo)}
                    <HelpTip position="top">
                      {model.intl.formatMessage(i18n.tooltipContactInfo)}
                    </HelpTip>
                  </InputFieldHeader>
                </LayoutTDHeading>
                <LayoutTDBody>
                  <ContactInfoCard>
                    <Text type="h5" white-space="nowrap" inline>
                      {model.intl.formatMessage(i18n.requestor)}
                      {' '}
                    </Text>
                    <RequesterInfoDiv>
                      <Text white-space="nowrap" inline>
                        {' '}
                        {model.requesterContactInfo.userName}
                        {' '}
                        <TertiarySpan>
                          (
                          {model.requesterContactInfo.email}
                          )
                        </TertiarySpan>

                      </Text>
                    </RequesterInfoDiv>
                    <MediumMarginDiv />
                    <InputFormGroup
                      id={useUID('self-reg-company-name-input')}
                      className="self-reg-company-name-input"
                      label={model.intl.formatMessage(i18n.headerRequesterCompany)}
                      onChange={event => changeInput({ field: 'requesterCompanyName', value: event.target.value })}
                      disabled={model.disableInput}
                      error={!!getValidationMessageForField({
                        fieldName: 'requesterCompanyName',
                        requesterCompanyName: model.form.requesterCompanyName,
                        showErrors: model.validationMessageToggles,
                        intl: model.intl,
                      }, model.globalAccountExpansionInfo.registrationType)}
                      message={getValidationMessageForField({
                        fieldName: 'requesterCompanyName',
                        requesterCompanyName: model.form.requesterCompanyName,
                        showErrors: model.validationMessageToggles,
                        intl: model.intl,
                      }, model.globalAccountExpansionInfo.registrationType)}
                    />
                    <MediumMarginDiv />
                    <InputFormGroup
                      id={useUID('self-reg-phone-number-input')}
                      className="self-reg-phone-number-input"
                      label={model.intl.formatMessage(i18n.headerPhone)}
                      onChange={event => changeInput({ field: 'requesterPhoneNumber', value: event.target.value })}
                      disabled={model.disableInput}
                      error={!!getValidationMessageForField({
                        fieldName: 'requesterPhoneNumber',
                        requesterPhoneNumber: model.form.requesterPhoneNumber,
                        showErrors: model.validationMessageToggles,
                        intl: model.intl,
                      }, model.globalAccountExpansionInfo.registrationType)}
                      message={getValidationMessageForField({
                        fieldName: 'requesterPhoneNumber',
                        requesterPhoneNumber: model.form.requesterPhoneNumber,
                        showErrors: model.validationMessageToggles,
                        intl: model.intl,
                      }, model.globalAccountExpansionInfo.registrationType)}
                    />
                  </ContactInfoCard>
                </LayoutTDBody>
              </tr> */}
              </tbody>
            </LayoutTable>
          </LargeCard>
        </div>
        <XLargeMarginDiv />
        <XLargeMarginDiv />
        <SubmitComponent {...{ control, handleSubmit }} />

        <XLargeMarginDiv />
      </CenteredBodyDiv>
    </MarketplaceExpansion>
  );
};

export default MarketplaceExpansionPage;
