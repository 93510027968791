import React, { FC } from 'react';
import { Button } from '@amzn/storm-ui';
import { useSelector } from 'react-redux';
import { selectMarketplaces, selectAccountName } from '../redux';
import { Marketplace } from '../model';
import { NO_BLANK_SPACE_REGEX } from '../../../shared/constants';

const SubmitComponent: FC = () => {
  const marketplaces: Marketplace[] = useSelector(selectMarketplaces);
  const accountName: string = useSelector(selectAccountName);
  const missingRequiredFields =
    !marketplaces ||
    marketplaces.filter((m) => m.selected).length == 0 ||
    !accountName ||
    !NO_BLANK_SPACE_REGEX.test(accountName);

  return (
    <Button
      className="open-reg-submit-button"
      primary
      disabled={missingRequiredFields}
    >
      Submit
    </Button>
  );
};

export default SubmitComponent;
