/**
 * Single account registration page
 */

/**
 * Selectors
 */
export const ACCOUNT_REGISTRATION_PAGE_ID = 'account-registration';
export const ACCOUNT_REGISTRATION_CREATE_BANNER_ID = `${ACCOUNT_REGISTRATION_PAGE_ID}-create-account`;

/**
 * translation keys
 */
export const REGISTRATION_TITLE_TRANSLATION_KEY = 'registration_title';

/**
 * General
 */
export const ACCOUNT_CREATION_REDIRECT_URL =
  '/ads-bg/billing/setup?isSingleAccount=true';

export const ACCOUNT_OVERVIEW_REDIRECT_URL = '/am/overview';
