import { DEFAULT_LOCALE } from '../i18n/utils';

/**
 * Update the Document title with the pageName
 */
export const setPageTitle = (pageName = '') => {
  const separator = ' | ';
  const baseTitle =
    document.title.split(separator)[document.title.split(separator).length - 1];

  if (pageName) {
    document.title = `${pageName}${separator}${baseTitle}`;
  } else {
    document.title = baseTitle;
  }
};

export const convertLocale = (locale = DEFAULT_LOCALE) => {
  return locale.replace('_', '-');
};

export const getAccountId = (entityId: string) => {
  return entityId ? entityId.replace('ENTITY', 'A') : '';
};
