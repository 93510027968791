import { Button, TextButton, Text } from '@amzn/storm-ui';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useIntl from '@i18n/useIntl';
import { toggleBusinessDetailsEditMode } from 'src/shared/state/account-settings-slice';
import * as formConstants from '@components/form/constants';
import useSubmit from 'src/pages/accountSettings/hooks/useSubmit';
import ConfirmationModal from '@components/modals/ConfirmationModal';
import {
  ACCOUNT_SETTINGS_CONFIRM_MODAL_ID,
  CONFIRMATION_MODAL_CANCEL_BUTTON_TRANSLATION_KEY,
  CONFIRMATION_MODAL_CONFIRM_BUTTON_TRANSLATION_KEY,
  CONFIRMATION_MODAL_HEADER_TRANSLATION_KEY,
  CONFIRMATION_MODAL_MESSAGE_TRANSLATION_KEY,
} from 'src/pages/accountSettings/constants';
import BusinessDetailsControllers from '@components/form/account/components/BusinessDetailsControllers';
import { AccountSettingsForm } from '../../style';
import { ActionsButtonsWrapper } from '@components/form/style';

const BusinessDetailsForm = () => {
  const { intl } = useIntl();
  const { handleSubmit } = useFormContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isLoading, onSubmit, onReset } = useSubmit(
    toggleBusinessDetailsEditMode,
  );

  const cancelChangesHandler = () => {
    setIsModalOpen(false);
    onReset();
  };

  return (
    <AccountSettingsForm onSubmit={handleSubmit(onSubmit)}>
      <BusinessDetailsControllers />
      <ActionsButtonsWrapper>
        <Button primary type="submit" loading={isLoading}>
          {intl.getMessage(formConstants.SUBMIT_BUTTON_TRANSLATION_KEY)}
        </Button>
        <TextButton onClick={() => setIsModalOpen(true)}>
          {intl.getMessage(formConstants.CANCEL_BUTTON_TRANSLATION_KEY)}
        </TextButton>
      </ActionsButtonsWrapper>
      <ConfirmationModal
        id={ACCOUNT_SETTINGS_CONFIRM_MODAL_ID}
        header={
          <Text fontSize={'medium'}>
            {intl.getMessage(CONFIRMATION_MODAL_HEADER_TRANSLATION_KEY)}
          </Text>
        }
        isOpen={isModalOpen}
        cancelButtonText={intl.getMessage(
          CONFIRMATION_MODAL_CANCEL_BUTTON_TRANSLATION_KEY,
        )}
        confirmButtonText={intl.getMessage(
          CONFIRMATION_MODAL_CONFIRM_BUTTON_TRANSLATION_KEY,
        )}
        onConfirm={cancelChangesHandler}
        onCancel={() => setIsModalOpen(false)}
      >
        <Text fontSize={'base'}>
          {intl.getMessage(CONFIRMATION_MODAL_MESSAGE_TRANSLATION_KEY)}
        </Text>
      </ConfirmationModal>
    </AccountSettingsForm>
  );
};

export default BusinessDetailsForm;
