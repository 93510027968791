import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageBundle } from '@amzn/arb-tools';
import { ChromeConfig, ChromeMenuItemConfig } from '@chrome/types';
import {
  ChromeKeys,
  HeaderTranslationKeys,
  MenuItemIds,
  PageLayoutType,
} from '@chrome/enums';
import { useGetConfigurationQuery } from '@services/configuration';
import useIntl from '@i18n/useIntl';
import { getAccountId } from '@utils/global';

/**
 * Chrome side-navigation items for access and settings pages
 */
export const getAccountSideNavigationItems = (
  intl: MessageBundle,
  entityId: string,
  navigate: (url: string) => void,
): ChromeMenuItemConfig[] => [
  {
    id: MenuItemIds.Account,
    label: intl.getMessage(MenuItemIds.Account),
    callback: entityId
      ? () => navigate(`/advertisingAccounts/${entityId}/account-settings`)
      : undefined,
    tag: 'new',
  },
  {
    id: MenuItemIds.Users,
    label: intl.getMessage(MenuItemIds.Users),
    callback: entityId
      ? () =>
          window.location.assign(
            `/am/managerAccounts/${entityId}/user/management`,
          )
      : undefined,
  },
  {
    id: MenuItemIds.ManagerAccount,
    label: intl.getMessage(MenuItemIds.ManagerAccount),
    callback: entityId
      ? () =>
          window.location.assign(
            `/advertisingAccounts/${entityId}/managerAccounts`,
          )
      : undefined,
  },
  {
    id: MenuItemIds.SellingAccount,
    label: intl.getMessage(MenuItemIds.SellingAccount),
    callback: entityId
      ? () =>
          window.location.assign(
            `/am/advertisingAccounts/${entityId}/retailPartners`,
          )
      : undefined,
  },
  {
    id: MenuItemIds.Brands,
    label: intl.getMessage(MenuItemIds.Brands),
    callback: entityId ? () => window.location.assign(`/brands`) : undefined,
  },
  {
    id: MenuItemIds.ThirdPartyApplication,
    label: intl.getMessage(MenuItemIds.ThirdPartyApplication),
    callback: entityId
      ? () => window.location.assign(`/am/thirdPartyApplications`)
      : undefined,
  },
  {
    id: MenuItemIds.DenyList,
    label: intl.getMessage(MenuItemIds.DenyList),
    callback: entityId ? () => window.location.assign(`/denylist`) : undefined,
  },
  {
    id: MenuItemIds.DataTransparency,
    label: intl.getMessage(MenuItemIds.DataTransparency),
    callback: entityId
      ? () =>
          window.location.assign(
            `/advertisingAccounts/${entityId}/data-transparency`,
          )
      : undefined,
  },
];

const useChromeConfiguration = (
  key: ChromeKeys,
): Partial<ChromeConfig> | undefined => {
  const { intl } = useIntl();
  const { data: { entityId = '', entityName = '' } = {} } =
    useGetConfigurationQuery();
  const navigate = useNavigate();

  const configuration = useMemo(() => {
    return new Map([
      [
        ChromeKeys.AccountSettings,
        {
          header: {
            pageName: intl.getMessage(
              HeaderTranslationKeys.AccessAndSettingsPageName,
            ),
            name: entityName,
            value: getAccountId(entityId) || '',
            key: intl.getMessage(HeaderTranslationKeys.AccessAndSettingsKey),
          },
          sideNavigation: {
            items: getAccountSideNavigationItems(intl, entityId, navigate),
            selected: MenuItemIds.Account,
          },
          layout: { pageLayout: PageLayoutType.Standard },
        },
      ],
      [
        ChromeKeys.AccountRegistration,
        {
          layout: { pageLayout: PageLayoutType.Light },
          enableMarketplaceSwitcher: false,
        },
      ],
      [
        ChromeKeys.RetailerAccountRegistrationPage,
        {
          layout: { pageLayout: PageLayoutType.Light },
          enableMarketplaceSwitcher: false,
        },
      ],
    ]);
  }, [entityId, entityName, getAccountSideNavigationItems]);

  return configuration.get(key);
};

export default useChromeConfiguration;
