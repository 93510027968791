/**
 * Registration form selectors
 */
export const DISABLE_SELLING_ACCOUNT_CHECKBOX =
  'disable-selling-account-checkbox';
export const TILE_GROUP_NAME = 'sellingAccounts';
export const SELLING_ACCOUNT_PAGINATION_STATUS =
  'selling-account-pagination-status';
export const SELLING_ACCOUNT_PAGINATION_FIRST_BUTTON =
  'selling-account-pagination-first-button';
export const SELLING_ACCOUNT_PAGINATION_PREV_BUTTON =
  'selling-account-pagination-prev-button';
export const SELLING_ACCOUNT_PAGINATION_NEXT_BUTTON =
  'selling-account-pagination-next-button';
export const SELLING_ACCOUNT_PAGINATION_LAST_BUTTON =
  'selling-account-pagination-last-button';

/**
 * Registration form selling accounts translation keys
 */
export const SELLING_ACCOUNT_TITLE_TRANSLATION_KEY =
  'registration_selling_accounts_title';
export const SELLING_ACCOUNT_MESSAGE_TRANSLATION_KEY =
  'registration_selling_accounts_message';
export const SELLING_ACCOUNT_DISABLE_CHECKBOX_LABEL_TRANSLATION_KEY =
  'registration_selling_account_disable_select_checkbox_label';
export const SELLING_ACCOUNT_EMPTY_STATE_TITLE_TRANSLATION_KEY =
  'registration_selling_accounts_empty_accounts_title';
export const SELLING_ACCOUNT_EMPTY_STATE_MESSAGE_TRANSLATION_KEY =
  'registration_selling_accounts_empty_accounts_message';
export const SELLING_ACCOUNT_PAGINATION_TOTAL_ITEMS_TRANSLATION_KEY =
  'registration_selling_accounts_pagination_total_items';
export const SELLING_ACCOUNT_ERROR_STATE_TITLE_TRANSLATION_KEY =
  'registration_selling_accounts_error_accounts_title';
export const SELLING_ACCOUNT_ERROR_STATE_MESSAGE_TRANSLATION_KEY =
  'registration_selling_accounts_error_accounts_message';
export const BUSINESS_DETAILS_ERROR_STATE_TITLE_TRANSLATION_KEY =
  'registration_business_details_error_title';
export const BUSINESS_DETAILS_ERROR_STATE_MESSAGE_TRANSLATION_KEY =
  'registration_business_details_error_message';
/**
 * General
 */
export const SELLING_ACCOUNT_PAGINATION_SIZE = 3;
export const TAKT_DISABLE_SELLING_ACCOUNT = 'disable-selling-accounts';
