import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/shared/state/store';
import {
  AlertConfig,
  AlertPlacement,
  removeAlert,
} from 'src/shared/state/alert-slice';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import {
  ErrorBanner,
  InfoBanner,
  SuccessBanner,
  WarningBanner,
} from './Banners';
import { BannerProps } from './Banner';

const StyledContainer = styled.div`
  > div:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`;

type AlertMessageType = {
  className?: string;
  placement?: AlertPlacement;
  enableScrollingIntoView?: boolean;
};

export const AlertMessage = (props: AlertMessageType) => {
  const {
    placement = 'mainView',
    className = '',
    enableScrollingIntoView,
  } = props;

  const dispatch = useDispatch();
  const alerts = useSelector((state: RootState) => state.alert.alerts);
  const alertsWrapperRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (alerts.length > 0 && enableScrollingIntoView) {
      alertsWrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [alerts]);

  const onClose = (alert: AlertConfig) => {
    dispatch(removeAlert(alert.id));
    if (alert.onClose) {
      alert.onClose(alert.id);
    }
  };

  const renderAlertMessages = (alerts: AlertConfig[]) => {
    const placementAlerts = filter(alerts, { placement });
    return map(placementAlerts, (alert, index) => {
      const bannerProps: BannerProps = {
        id: alert.id,
        children: alert.message,
        duration: alert.duration,
        customOnCloseCallback: () => onClose(alert),
        props: {
          header: alert.header,
          withCloseButton: alert.withCloseButton,
        },
      };

      switch (alert.type) {
        case 'success':
          return <SuccessBanner {...bannerProps} key={`alert-${index}`} />;
        case 'warning':
          return <WarningBanner {...bannerProps} key={`alert-${index}`} />;
        case 'error':
          return <ErrorBanner {...bannerProps} key={`alert-${index}`} />;
        default:
          return <InfoBanner {...bannerProps} key={`alert-${index}`} />;
      }
    }).reverse();
  };

  if (isEmpty(alerts)) {
    return <></>;
  }

  return (
    <div ref={alertsWrapperRef}>
      <StyledContainer className={className}>
        {renderAlertMessages(alerts)}
      </StyledContainer>
    </div>
  );
};
