import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from '../../shared/state/store';
import { ApiResourceState, bffApi } from '../../shared/api';
import { OpenRegistrationState, Marketplace } from './model';
import { AxiosResponse } from 'axios';

const marketplacesAdapter = createEntityAdapter<Marketplace>({
  sortComparer: (a, b) => a.domainName.localeCompare(b.domainName),
  selectId: (m) => m.obfuscatedId,
});

const initialState: OpenRegistrationState = {
  marketplaces: marketplacesAdapter.getInitialState({
    status: ApiResourceState.IDLE,
  }),
  accountName: '',
};

export const OPEN_REGISTRATION_SLICE_NAME = 'openRegistration';

export const openRegistrationSlice = createSlice({
  name: OPEN_REGISTRATION_SLICE_NAME,
  initialState: initialState,
  reducers: {
    addSelectedMarketplace: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (state.marketplaces.status === ApiResourceState.SUCCEEDED) {
        const marketplace = state.marketplaces.entities[action.payload];
        if (marketplace != null) {
          marketplace.selected = true;
        }
      }
    },
    removeSelectedMarketplace: (state, action: PayloadAction<string>) => {
      if (state.marketplaces.status === ApiResourceState.SUCCEEDED) {
        const marketplace = state.marketplaces.entities[action.payload];
        if (marketplace != null) {
          marketplace.selected = false;
        }
      }
    },
    updateAccountName: (state, action: PayloadAction<string>) => {
      state.accountName = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMarketplaces.pending, (state) => {
        state.marketplaces.status = ApiResourceState.LOADING;
      })
      .addCase(fetchMarketplaces.fulfilled, (state, action) => {
        state.marketplaces.status = ApiResourceState.SUCCEEDED;
        marketplacesAdapter.upsertMany(state.marketplaces, action.payload);
      })
      .addCase(fetchMarketplaces.rejected, (state) => {
        state.marketplaces.status = ApiResourceState.FAILED;
      });
  },
});

// This contract is defined by the API response
type FetchMarketplacesResponse = {
  allMarketplaces: MarketplaceFromApi[];
};

type MarketplaceFromApi = {
  amsDomain: string;
  countryName: string;
  domainName: string;
  domainShortName: string;
  expandable: boolean;
  obfuscatedMarketplaceId: string;
  primaryDomain: string;
};

export const fetchMarketplaces = createAsyncThunk(
  `${OPEN_REGISTRATION_SLICE_NAME}/fetchMarketplaces`,
  async (): Promise<Marketplace[]> => {
    const response: AxiosResponse<FetchMarketplacesResponse> = await bffApi.get(
      '/advertisingAccounts/registration/api/v1/registration/get-marketplaces',
    );
    const marketplaces = response.data.allMarketplaces.map((m) => {
      return {
        selected: false,
        obfuscatedId: m.obfuscatedMarketplaceId,
        domainName: m.domainName,
      };
    });
    return marketplaces;
  },
);

export const {
  addSelectedMarketplace,
  removeSelectedMarketplace,
  updateAccountName,
} = openRegistrationSlice.actions;

export const { selectAll: selectMarketplaces } =
  marketplacesAdapter.getSelectors<RootState>(
    (state) => state.openRegistration.marketplaces,
  );

export function selectFetchMarketplacesStatus(state: RootState): string {
  return state.openRegistration.marketplaces.status;
}

export function selectAccountName(state: RootState): string {
  return state.openRegistration.accountName;
}

export default openRegistrationSlice.reducer;
