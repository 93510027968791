import React from 'react';
import InputController from '@components/form/InputController';
import useIntl from '@i18n/useIntl';
import * as accountInfoConstants from '@components/form/account/constants';
import * as formConstants from '@components/form/constants';
import { ControllersWrapper } from '@components/form/style';

type AccountDetailsControllersProps = {
  labelKey?: string;
};

const AccountDetailsControllers = (props: AccountDetailsControllersProps) => {
  const { labelKey = accountInfoConstants.ACCOUNT_NAME_FIELD_EDIT_LABEL } =
    props;
  const { intl } = useIntl();

  return (
    <ControllersWrapper>
      <InputController
        label={intl.getMessage(labelKey)}
        id={accountInfoConstants.ACCOUNT_NAME_FIELD_ID}
        controllerName={accountInfoConstants.ACCOUNT_NAME_FIELD_CONTROLLER_NAME}
        registerOptions={{
          required: intl.getMessage(
            formConstants.REQUIRED_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
          ),
        }}
        helpTipText={intl.getMessage(
          accountInfoConstants.ACCOUNT_NAME_FIELD_TOOLTIP_TRANSLATION_KEY,
        )}
      />
    </ControllersWrapper>
  );
};

export default AccountDetailsControllers;
