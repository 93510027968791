import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Business } from '@models/account';
import { RootState } from 'src/shared/state/store';
import BusinessDetailsForm from 'src/pages/accountSettings/components/BusinessDetails/BusinessDetailsForm';
import BusinessDetailsViewer from './BusinessDetailsViewer';

export interface BusinessDetailsProps {
  business: Business;
}
const BusinessDetails = ({ business }: BusinessDetailsProps) => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      business,
    },
  });

  /**
   * Update the default value when the form is submitted
   */
  useEffect(() => {
    methods.reset({
      business: business,
    });
  }, [business]);

  const isEditMode = useSelector(
    (state: RootState) => state.accountSettings.businessDetailsEditMode,
  );

  return (
    <FormProvider {...methods}>
      {isEditMode ? <BusinessDetailsForm /> : <BusinessDetailsViewer />}
    </FormProvider>
  );
};

export default BusinessDetails;
