/**
 * Registration form selectors
 */
export const SELLING_ACCOUNT_TILE_GROUP_ID = 'selling-account-tile-group';

/**
 * Registration form translation keys
 */
export const REGISTRATION_USER_DETAILS_TITLE_TRANSLATION_KEY =
  'registration_user_details_title';
export const REGISTRATION_USER_DETAILS_INFO_TRANSLATION_KEY =
  'registration_user_details_info';
export const REGISTRATION_USER_DETAILS_LINK_TRANSLATION_KEY =
  'registration_user_details_link';
export const REGISTRATION_FORM_TITLE_TRANSLATION_KEY =
  'registration_form_title';
/**
 * Register with business email view translation keys
 */
export const REGISTER_WITH_BUSINESS_EMAIL_CARD_TITLE_TRANSLATION_KEY =
  'registration_register_with_business_email_title';
export const REGISTER_WITH_BUSINESS_EMAIL_CARD_TEXT_TRANSLATION_KEY =
  'registration_register_with_business_email_text';
export const REGISTER_WITH_BUSINESS_EMAIL_CARD_SIGNED_IN_TEXT_TRANSLATION_KEY =
  'registration_register_with_business_email_signed_in_text';
export const REGISTER_WITH_BUSINESS_EMAIL_SWITCH_ACCOUNT_BUTTON_TRANSLATION_KEY =
  'registration_register_with_business_email_switch_account_button';
export const REGISTER_WITH_BUSINESS_EMAIL_CONTINUE_BUTTON_TRANSLATION_KEY =
  'registration_register_with_business_email_continue_button';
