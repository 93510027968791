import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'src/shared/api/api';

export interface LocationOption {
  value: string;
  label: string;
}

const BASE_URL = '/ads-account/api/v1/';
const COUNTRIES_ENDPOINT = 'countries';
const STATES_ENDPOINT = 'states';
const REDUCER_PATH = 'addressApi';

/**
 * The address API will cache the list of countries and will cache the states for each country
 */
export const addressApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getCountries: builder.query<LocationOption[], void>({
      query: () => ({ url: COUNTRIES_ENDPOINT, method: 'GET' }),
    }),
    getStates: builder.query<LocationOption[], string>({
      query: (countryCode: string) => ({
        url: `${STATES_ENDPOINT}/${countryCode}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCountriesQuery, useGetStatesQuery } = addressApi;
