import axios from 'axios';
import { EntityState } from '@reduxjs/toolkit';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Function to create a baseQuery with common headers and a customizable baseUrl
export const baseQueryWithHeaders = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === 'test'
        ? new URL(baseUrl, location.origin).href
        : baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      headers.set('X-Csrf-Token', '1');
      return headers;
    },
  });

export enum ApiResourceState {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}

// This is used for state that is obtain/managed by API calls
export type APIServedResource<T> =
  | { status: ApiResourceState.IDLE }
  | { status: ApiResourceState.LOADING }
  | { status: ApiResourceState.FAILED }
  | { status: ApiResourceState.SUCCEEDED; value: T };

export type APIServedCollection<T> = EntityState<T> & {
  status: ApiResourceState;
};

export const bffApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const putWithCsrf = async (url: string, data: any) => {
  const token: string = (await bffApi.get('/ads-account/api/v1/csrf')).data;
  return await bffApi.put(url, data, {
    headers: {
      'anti-csrftoken-a2z': token,
    },
  });
};

type ClientSideExceptionResponse = {
  errorCode: string;
  httpCode: number;
  reason: string;
};
type AxiosErrorWithClientSideExceptionResponse = {
  response: {
    data: ClientSideExceptionResponse;
  };
};
const isClientSideExceptionResponse = (
  x: any,
): x is ClientSideExceptionResponse =>
  !!x &&
  typeof x === 'object' &&
  !Array.isArray(x) &&
  'errorCode' in x &&
  typeof x.errorCode === 'string' &&
  'httpCode' in x &&
  typeof x.httpCode === 'number' &&
  'reason' in x &&
  typeof x.reason === 'string';
export const containsClientSideErrorCode = (
  e: any,
): e is AxiosErrorWithClientSideExceptionResponse =>
  axios.isAxiosError(e) &&
  !!e.response &&
  !!e.response.data &&
  isClientSideExceptionResponse(e.response.data);
