import React, { FC } from 'react';
import { Text, HelpTip, Icon } from '@amzn/storm-ui';
import { useSelector } from 'react-redux';
import { FormValues, MarketplaceInfo } from '../model';
import {
  selectMarketplaces,
  selectIsSubmitLoading,
  selectGetGlobalAccountExpansionStatus,
} from '../redux';
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import {
  MarketplaceCard,
  MarketplaceCardInnerDiv,
  MarketplaceInfoDiv,
  MarketplaceCheckBoxDiv,
  MediumMarginDiv,
  ErrorText,
  ErrorIcon,
  LayoutTDHeading,
  LayoutTDBody,
  LayoutTDBodyCentered,
  InputFieldHeader,
} from '../style';
import CheckboxWithId from '../../../shared/components/CheckboxWithId';
import { ApiResourceState } from 'src/shared/api';
import { useBundle } from '@amzn/react-arb-tools';

type MarketplacesComponentProps = {
  control: Control<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
};

const MarketplacesComponent: FC<MarketplacesComponentProps> = ({
  control,
  setValue,
  watch,
  getValues,
  trigger,
}) => {
  useFieldArray({
    name: 'selectedMarketplaces',
    control: control,
    rules: {
      validate: (values) => {
        if (values.filter((v) => v.selected).length < 1) {
          if (values.filter((v) => v.isTouched).length > 0) {
            return 'mustSelect';
          }
          return 'noneSelected';
        }
        return undefined;
      },
    },
  });
  const marketplaces: MarketplaceInfo[] = useSelector(selectMarketplaces);
  const disableInput: boolean = useSelector(selectIsSubmitLoading);
  const getGlobalAccountExpansionStatus = useSelector(
    selectGetGlobalAccountExpansionStatus,
  );
  const showSpinner =
    getGlobalAccountExpansionStatus === ApiResourceState.LOADING;
  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');
  if (isBundleLoading) {
    return null;
  }

  const component = marketplaces.map((m, index) => (
    <Controller
      key={m.obfuscatedMarketplaceId}
      control={control}
      defaultValue={{ ...m, selected: false, isTouched: false }}
      name={`selectedMarketplaces.${index}`}
      render={() => {
        return (
          <MarketplaceCard
            onClick={
              m.expandable
                ? () => {
                    setValue(`selectedMarketplaces.${index}`, {
                      ...m,
                      selected: !getValues(
                        `selectedMarketplaces.${index}.selected`,
                      ),
                      isTouched: true,
                    });
                    trigger('selectedMarketplaces');
                  }
                : undefined
            }
            aria-disabled={!m.expandable || disableInput}
          >
            <MarketplaceCardInnerDiv>
              <MarketplaceInfoDiv>
                <Text>
                  <b>{m.primaryDomain}</b>
                </Text>
              </MarketplaceInfoDiv>
              <MarketplaceInfoDiv>
                {/* TODO get translation from ARMS */}
                <Text>{'(i18n[m.domainName])'}</Text>
              </MarketplaceInfoDiv>
              <MarketplaceInfoDiv>
                <ErrorText hidden={m.expandable}>
                  <ErrorIcon type="exclamation" />
                  &nbsp;&nbsp;
                  {intl.getMessage('acc_mgmt_modal_marketplace_unavailable')}
                </ErrorText>
              </MarketplaceInfoDiv>
              <MarketplaceCheckBoxDiv>
                <CheckboxWithId
                  idSuffix={`self-reg-marketplace-checkbox-${m.domainShortName}`}
                  label=""
                  className="self-reg-marketplace-checkbox"
                  value={m.obfuscatedMarketplaceId}
                  // watch is used to listen to updates on form state to only re-render the component that needs the value
                  checked={watch(`selectedMarketplaces.${index}.selected`)}
                  disabled={!m.expandable || disableInput}
                />
              </MarketplaceCheckBoxDiv>
            </MarketplaceCardInnerDiv>
          </MarketplaceCard>
        );
      }}
    />
  ));
  return (
    <>
      <LayoutTDHeading>
        <InputFieldHeader type="h5">
          {intl.getMessage('aum_aa_create_marketplaces')}
          <HelpTip position="top">
            {intl.getMessage(
              'aum_aa_create_tooltip_marketplaces_multiple_selection',
            )}
          </HelpTip>
        </InputFieldHeader>
      </LayoutTDHeading>
      <LayoutTDBody>{component}</LayoutTDBody>
      <LayoutTDBodyCentered hidden={!showSpinner}>
        <Icon type="spinner" size="3x" />
        <MediumMarginDiv />
      </LayoutTDBodyCentered>
    </>
  );
};

export default MarketplacesComponent;
