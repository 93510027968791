/**
 * RetailerAccountRegistrationErrorBanner Component
 */

/**
 * Translation keys
 */
export const RETAILER_ACCOUNT_REGISTRATION_UNAVAILABLE_CURRENT_USER_TITLE_TRANSLATION_KEY =
  'invitation_for_different_email_title';
export const RETAILER_ACCOUNT_REGISTRATION_UNAVAILABLE_CURRENT_USER_MESSAGE_TRANSLATION_KEY =
  'invitation_for_different_email_error_message';
export const RETAILER_ACCOUNT_REGISTRATION_EXPIRED_TOKEN_TITLE_TRANSLATION_KEY =
  'invitation_expired_title';
export const RETAILER_ACCOUNT_REGISTRATION_EXPIRED_TOKEN_MESSAGE_TRANSLATION_KEY =
  'invitation_expired_message';
export const RETAILER_ACCOUNT_REGISTRATION_REVOKED_TOKEN_TITLE_TRANSLATION_KEY =
  'invitation_revoked_title';
export const RETAILER_ACCOUNT_REGISTRATION_REVOKED_TOKEN_MESSAGE_TRANSLATION_KEY =
  'invitation_revoked_message';
export const RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_TITLE_TRANSLATION_KEY =
  'invitation_invalid_title';
export const RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_MESSAGE_TRANSLATION_KEY =
  'invitation_invalid_message';
export const RETAILER_ACCOUNT_REGISTRATION_REDEEMED_TOKEN_TITLE_TRANSLATION_KEY =
  'invitation_redeemed_title';
export const RETAILER_ACCOUNT_REGISTRATION_REDEEMED_TOKEN_MESSAGE_TRANSLATION_KEY =
  'invitation_redeemed_message';
