import React, { FC } from 'react';
import {
  LayoutTDHeading,
  LayoutTDBody,
  MiniMarginNoLeftDiv,
  ErrorText,
  ErrorIcon,
} from '../style';
import { Control, useFormState } from 'react-hook-form';
import { FormValues } from '../model';
import { useBundle } from '@amzn/react-arb-tools';

type MarketplacesErrorsComponentProps = {
  control: Control<FormValues>;
};

const MarketplacesErrorsComponent: FC<MarketplacesErrorsComponentProps> = ({
  control,
}) => {
  const { errors } = useFormState({
    control: control,
    name: 'selectedMarketplaces',
  });
  const [intl, isBundleLoading] = useBundle('pages.marketplaceExpansion');
  if (isBundleLoading) {
    return null;
  }
  return (
    <tr hidden={errors.selectedMarketplaces?.root?.message !== 'mustSelect'}>
      <LayoutTDHeading />
      <LayoutTDBody>
        <MiniMarginNoLeftDiv>
          <ErrorText>
            <ErrorIcon type="exclamation" />
            &nbsp;&nbsp;
            {intl.getMessage('aum_aa_create_validation_marketplace')}
          </ErrorText>
        </MiniMarginNoLeftDiv>
      </LayoutTDBody>
    </tr>
  );
};

export default MarketplacesErrorsComponent;
