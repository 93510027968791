import { RootState } from 'src/shared/state/store';
import { ApiResourceState, bffApi } from '../../shared/api';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  MarketplaceExpansionState,
  MarketplaceInfo,
  SuccessAndFailureMap,
  RegistrationType,
} from './model';
import { AxiosResponse } from 'axios';

const marketplacesAdapter = createEntityAdapter<MarketplaceInfo>({
  sortComparer: (a, b) => a.domainName.localeCompare(b.domainName),
  selectId: (m) => m.obfuscatedMarketplaceId,
});

const initialState: MarketplaceExpansionState = {
  requesterContactInfo: null, // relevant to vendor and RED
  marketplaces: marketplacesAdapter.getInitialState({
    status: ApiResourceState.IDLE,
    validationsEnabled: false,
  }),
  globalAccountExpansionInfo: { status: ApiResourceState.IDLE },
  submitExtendAccountState: { status: ApiResourceState.IDLE },
  apiErrorMessageId: null,
};

export const MARKETPLACE_EXPANSION_SLICE_NAME = 'marketplaceExpansion';

export const marketplaceExpansionSlice = createSlice({
  name: MARKETPLACE_EXPANSION_SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGlobalAccountInfo.pending, (state) => {
        state.marketplaces.status = ApiResourceState.LOADING;
        state.globalAccountExpansionInfo.status = ApiResourceState.LOADING;
      })
      .addCase(fetchGlobalAccountInfo.fulfilled, (state, action) => {
        state.marketplaces.status = ApiResourceState.SUCCEEDED;
        const marketplacesFromResponse = action.payload.marketplaceInfoList;
        const marketplaces = marketplacesFromResponse.map((m) => {
          return {
            ...m,
            selected: false,
          };
        });
        marketplacesAdapter.upsertMany(state.marketplaces, marketplaces);
        state.globalAccountExpansionInfo = {
          status: ApiResourceState.SUCCEEDED,
          value: {
            globalAccountId: action.payload.globalAccountId,
            globalAccountName: action.payload.globalAccountName,
            registrationType: action.payload.registrationType,
          },
        };
      })
      .addCase(fetchGlobalAccountInfo.rejected, (state) => {
        state.marketplaces.status = ApiResourceState.FAILED;
        state.globalAccountExpansionInfo.status = ApiResourceState.FAILED;
        // Note this is different from AUMS, but the original string, 'errorGetGlobalAccountExpansionInfo'
        // does not exist
        state.apiErrorMessageId =
          'aum_aa_create_error_get_expandable_marketplaces';
      })
      .addCase(submitExtendAccount.pending, (state) => {
        state.submitExtendAccountState.status = ApiResourceState.LOADING;
      })
      .addCase(submitExtendAccount.fulfilled, (state, action) => {
        state.submitExtendAccountState = {
          value: action.payload,
          status: ApiResourceState.SUCCEEDED,
        };
      })
      .addCase(submitExtendAccount.rejected, (state) => {
        state.submitExtendAccountState.status = ApiResourceState.FAILED;
        state.apiErrorMessageId = 'aum_aa_create_error_create_aa';
      });
  },
});

type FetchGlobalAccountInfoResponse = {
  globalAccountId: string;
  globalAccountName: string;
  marketplaceInfoList: {
    obfuscatedMarketplaceId: string;
    primaryDomain: string;
    domainName: string;
    domainShortName: string;
    expandable: boolean;
  }[];
  registrationType: RegistrationType;
};

export const fetchGlobalAccountInfo = createAsyncThunk(
  `${MARKETPLACE_EXPANSION_SLICE_NAME}/fetchGlobalAccountExpansionInfo`,
  async (
    globalAccountId: string,
  ): Promise<FetchGlobalAccountInfoResponse & NonNullable<unknown>> => {
    const queryParams = {
      globalAccountId,
    };
    const response: AxiosResponse<FetchGlobalAccountInfoResponse> =
      await bffApi.get(
        `/advertisingAccounts/api/v1/registration/getGlobalAccountExpansionInfo`,
        {
          params: queryParams,
        },
      );
    if (response.data.globalAccountId !== globalAccountId) {
      throw new Error(
        `Mismatch accountId ${response.data.globalAccountId} and ${globalAccountId}`,
      );
      // TODO log the error
    }
    return response.data;
  },
);

type ExtendAccountRequest = {
  accountName: string;
  requesterName: string; //TODO for vendor
  requesterEmail: string; //TODO for vendor
  requesterCompanyName: string; //TODO for vendor
  requesterPhoneNumber: string; //TODO for vendor
  vendorApproverEmail: string; //TODO for vendor
  selectedAccountEntityId: string;
  marketplaceList: string[];
  redirectOrigin: string | null;
};

export const submitExtendAccount = createAsyncThunk(
  `${MARKETPLACE_EXPANSION_SLICE_NAME}/submitExtendAccount`,
  async (request: ExtendAccountRequest): Promise<SuccessAndFailureMap> => {
    const data = { form: request };
    const response: AxiosResponse<SuccessAndFailureMap> = await bffApi.post(
      `/advertisingAccounts/api/v1/registration/extendExistingAccount`,
      data,
    );
    if (response.data.failure.length > 0 || response.data.success.length < 1) {
      throw new Error(
        `Failed to create account ${request.selectedAccountEntityId}`,
      );
      // TODO log the error
    }
    return response.data;
  },
);

export function selectDisableInput(state: RootState): boolean {
  const marketplaceExpansion = state.marketplaceExpansion;
  if (
    marketplaceExpansion.marketplaces.status === ApiResourceState.SUCCEEDED &&
    marketplaceExpansion.globalAccountExpansionInfo.status ===
      ApiResourceState.SUCCEEDED
  ) {
    if (
      marketplaceExpansion.submitExtendAccountState.status ===
        ApiResourceState.IDLE ||
      marketplaceExpansion.submitExtendAccountState.status ===
        ApiResourceState.FAILED
    ) {
      // return false, only if we have loaded all the values on initialization
      // and submit is not requested or failed
      return false;
    }
  }
  return true;
}

export const { selectAll: selectMarketplaces } =
  marketplacesAdapter.getSelectors<RootState>(
    (state) => state.marketplaceExpansion.marketplaces,
  );

export function selectGetGlobalAccountExpansionStatus(
  state: RootState,
): ApiResourceState {
  return state.marketplaceExpansion.globalAccountExpansionInfo.status;
}

export function selectRegistrationType(
  state: RootState,
): RegistrationType | null {
  if (
    state.marketplaceExpansion.globalAccountExpansionInfo.status ===
    ApiResourceState.SUCCEEDED
  ) {
    return state.marketplaceExpansion.globalAccountExpansionInfo.value
      .registrationType;
  }
  return null;
}

export function selectCurrentGlobalAccountName(
  state: RootState,
): string | null {
  if (
    state.marketplaceExpansion.globalAccountExpansionInfo.status ===
    ApiResourceState.SUCCEEDED
  ) {
    return state.marketplaceExpansion.globalAccountExpansionInfo.value
      .globalAccountName;
  }
  return null;
}

export function selectApiErrorMessageId(state: RootState): string | null {
  return state.marketplaceExpansion.apiErrorMessageId;
}

export function selectDoRedirectToAccountManagement(state: RootState): boolean {
  return (
    state.marketplaceExpansion.submitExtendAccountState.status ===
    ApiResourceState.SUCCEEDED
  );
}

export function selectIsSubmitLoading(state: RootState): boolean {
  return (
    state.marketplaceExpansion.submitExtendAccountState.status ===
    ApiResourceState.LOADING
  );
}

export default marketplaceExpansionSlice.reducer;
