import styled from 'styled-components';
import { Card, Text, Icon, InputFormGroup } from '@amzn/storm-ui';

export const CenteredBodyDiv = styled.div`
  width: 840px;
  margin: auto;
  padding: 24px;
`;

export const XLargePaddingTopDiv = styled.div`
  padding-block-start: ${({ theme }) => theme.spacing.xlarge};
  background: ${({ theme }) => theme.palette.concrete};
`;

export const XLargeMarginDiv = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

export const MediumMarginDiv = styled.div`
  margin: ${({ theme }) => theme.spacing.medium};
`;

export const PageTitle = styled(Text)`
  text-align: center;
`;

export const PageLoadingSpinnerDiv = styled.div`
  margin: 10% 0% 0% 45%;
`;

export const LayoutTable = styled.table`
  border: none !important; /* stylelint-disable-line declaration-no-important */
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
`;

export const LayoutTD = styled.td`
  background: white;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
`;

export const LayoutTDHeading = styled.td`
  background: white;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  width: 160px;
  vertical-align: top !important; /* stylelint-disable-line declaration-no-important */
`;

export const LayoutTDBody = styled.td`
  background: white;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  width: 620px;
`;

export const LayoutTDBodyCentered = styled.td`
  background: white;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: 0px 0px 0px 175px !important; /* stylelint-disable-line declaration-no-important */
  width: 450px;
`;

export const MarketplaceCard = styled(Card)`
  background: ${({ theme }) => theme.palette.concrete};
  width: 168px;
  height: 45px;
  padding: 10px;
  margin: 0px 14px 14px 0px;
  border-radius: 6px;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  &[aria-disabled='true']:hover {
    cursor: not-allowed;
  }
`;

export const MarketplaceCheckBoxDiv = styled.div`
  width: 20%;
  display: block;
  float: left; /* stylelint-disable-line */
  position: absolute;
`;

export const MarketplaceCardInnerDiv = styled.div`
  position: relative;
  top: -25px;
  left: -25px;
`;

export const MarketplaceInfoDiv = styled.div`
  width: 70%;
  padding-inline-start: ${({ theme }) => theme.spacing.xlarge};
  display: block;
  float: left; /* stylelint-disable-line */
  text-align: start;
  white-space: nowrap;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.palette.auired};
`;

export const ErrorIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.auired};
`;

export const InputFieldHeader = styled(Text)`
  text-align: end;
  margin-inline-end: ${({ theme }) => theme.spacing.small};
`;

export const AccountNameInput = styled(InputFormGroup)`
  width: 400px !important; /* stylelint-disable-line declaration-no-important */
`;

export const LargeCard = styled(Card)`
  width: 840px;
  padding: 0px;
  margin: auto;
`;

export const MiniMarginNoLeftDiv = styled.div`
  margin: ${({ theme }) => theme.spacing.mini};
  margin-inline-start: 0px;
`;

export const TextShiftedUp = styled(Text)`
  margin-block-start: -${({ theme }) => theme.spacing.mini};
`;

export const MarketplaceInfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.azure};
`;

export const ContactInfoCard = styled(Card)`
  background: ${({ theme }) => theme.palette.alabaster};
  width: 400px;
`;

export const RequesterInfoDiv = styled.div`
  display: inline-block;
`;

export const TertiarySpan = styled.span`
  color: ${({ theme }) => theme.palette.boulder};
  font-size: 12px;
`;

export const AcceptButtonRowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 35%;
  margin: 0;

  .self-reg-cancel-button {
    margin-inline-start: ${({ theme }) => theme.spacing.small};
    margin-inline-end: ${({ theme }) => theme.spacing.small};
  }

  .non-endemic-cancel-button {
    margin-inline-start: ${({ theme }) => theme.spacing.small};
    margin-inline-end: ${({ theme }) => theme.spacing.small};
  }
`;

export const AcceptTextContainer = styled.div`
  display: flex;
  width: 65%;
  margin: 0;
  justify-content: flex-start;
`;

export const MarketplaceExpansion = styled.div`
  background-color: #f3f3f3;
`;
