import { FC } from 'react';
import { useLocation } from 'react-router-dom';

const NotFoundPage: FC = () => {
  const location = useLocation();
  window.location.assign(`/404?requestSource=${location.pathname}`);
  return null;
};

export default NotFoundPage;
