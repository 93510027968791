import { MessageBundle } from '@amzn/arb-tools';
import * as constants from './constants';
import { InvitationStatusCode } from '@services/registration/types';

interface invitationStatus {
  title?: string;
  message?: string;
}

export const getInvitationStatus = (
  intl: MessageBundle,
  loginEmail = '',
): Partial<Record<InvitationStatusCode, invitationStatus>> => {
  return {
    [InvitationStatusCode.UnavailableToCurrentUser]: {
      title: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_UNAVAILABLE_CURRENT_USER_TITLE_TRANSLATION_KEY,
      ),
      message: intl.formatMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_UNAVAILABLE_CURRENT_USER_MESSAGE_TRANSLATION_KEY,
        {
          loginEmail: loginEmail,
        },
      ),
    },
    [InvitationStatusCode.Expired]: {
      title: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_EXPIRED_TOKEN_TITLE_TRANSLATION_KEY,
      ),
      message: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_EXPIRED_TOKEN_MESSAGE_TRANSLATION_KEY,
      ),
    },
    [InvitationStatusCode.Revoked]: {
      title: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_REVOKED_TOKEN_TITLE_TRANSLATION_KEY,
      ),
      message: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_REVOKED_TOKEN_MESSAGE_TRANSLATION_KEY,
      ),
    },
    [InvitationStatusCode.Invalid]: {
      title: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_TITLE_TRANSLATION_KEY,
      ),
      message: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_MESSAGE_TRANSLATION_KEY,
      ),
    },
    [InvitationStatusCode.Redeemed]: {
      title: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_REDEEMED_TOKEN_TITLE_TRANSLATION_KEY,
      ),
      message: intl.getMessage(
        constants.RETAILER_ACCOUNT_REGISTRATION_REDEEMED_TOKEN_MESSAGE_TRANSLATION_KEY,
      ),
    },
  };
};
