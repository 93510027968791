/**
 * PLD registration page
 */

/**
 * Selectors
 */
export const RETAILER_ACCOUNT_REGISTRATION_PAGE_ID =
  'retailer-account-registration';
export const RETAILER_ACCOUNT_REGISTRATION_CREATE_BANNER_ID = `${RETAILER_ACCOUNT_REGISTRATION_PAGE_ID}-create-account`;
export const RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_BANNER_ID =
  'retailer-create-account-invalidated-token';

/**
 * Translation keys
 */
export const RETAILER_ACCOUNT_REGISTRATION_TITLE_TRANSLATION_KEY =
  'retailer_account_registration_title';

/**
 * General
 */
export const ACCOUNT_CREATION_REDIRECT_URL = '/cb';
