import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { MbmProvider } from '@amzn/react-arb-tools';
import { useGetConfigurationQuery } from '@services/configuration';
import { getMbmOption } from '@i18n/utils';
import BundleProvider from '@i18n/BundleProvider';
import Spinner from '@components/Spinner';
import axiosInstance, {
  attachPublicApiRequestInterceptor,
} from './api/axios-instance';

const Root = () => {
  const { data, isLoading, error, isSuccess } = useGetConfigurationQuery();

  useEffect(() => {
    if (isSuccess && data) {
      attachPublicApiRequestInterceptor(axiosInstance, data);
    }
  }, [data, isSuccess]);

  if (error) {
    window.location.assign('/404');
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <MbmProvider {...getMbmOption(data?.locale)}>
      <BundleProvider>
        <Outlet />
      </BundleProvider>
    </MbmProvider>
  );
};

export default Root;
