import React, { ReactNode } from 'react';
import { Text } from '@amzn/storm-ui';
import { EmptyAndErrorWrapper } from 'src/pages/registration/style';
import isString from 'lodash/isString';

type EmptyAndErrorContainerProps = {
  title: ReactNode;
  message: ReactNode;
};

const EmptyAndErrorContainer = ({
  title,
  message,
}: EmptyAndErrorContainerProps) => {
  return (
    <EmptyAndErrorWrapper>
      <Text type="h5">{title}</Text>
      {isString(message) ? <Text>{message}</Text> : message}
    </EmptyAndErrorWrapper>
  );
};

export default EmptyAndErrorContainer;
