import { KeyboardEvent } from 'react';

// Prevent reloading the page when clicking the Enter button
export const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
  const { key } = e;

  if (key !== 'Enter') {
    return;
  }

  e.preventDefault();
};
