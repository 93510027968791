import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { csrfTokenManager } from 'src/shared/api/utils';
import {
  ACCEPT_HEADER,
  AMAZON_ADS_ACCOUNT_ID_HEADER,
  AMAZON_ADVERTISING_API_ADVERTISER_ID_HEADER,
  AMAZON_ADVERTISING_API_CLIENT_ID_HEADER,
  AMAZON_ADVERTISING_API_CSRF_DATA_HEADER,
  AMAZON_ADVERTISING_API_IS_IMPERSONATOR_HEADER,
  AMAZON_ADVERTISING_API_IS_PORTAL_SERVER,
  AMAZON_ADVERTISING_API_MARKETPLACE_ID_HEADER,
  CONTENT_TYPE_HEADER,
  PREFER_HEADER,
  PUBLIC_API_IDENTIFIER_HEADER,
  X_AMZ_REQUEST_CSRF_TOKEN_HEADER,
} from '../constants';
import { Configuration } from '../services/configuration';

const baseURL = '';
// Support MSW (Mock Service Worker) when Testing using RTK Query, as RTK Query requests were not being intercepted.
// For more details, visit: https://mswjs.io/docs/runbook#rtk-query-requests-are-not-intercepted
const developmentBaseURL = new URL(baseURL, location.origin).href;

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'test' ? developmentBaseURL : baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchCsrfToken = async () => {
  try {
    const { data } = await axiosInstance.get('/ads-account/api/v1/csrf');
    return data;
  } catch (e) {
    throw new Error(`Unable fetch CSRF token, ${e}`);
  }
};

export const attachPublicApiRequestInterceptor = (
  axiosClient: AxiosInstance,
  {
    isSpoofer,
    clientId,
    marketplaceId,
    entityId,
    publicApiUrl,
    globalAccountId,
  }: Configuration,
) => {
  axiosClient.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
      if (
        request.headers.has(PUBLIC_API_IDENTIFIER_HEADER) &&
        request.headers.get(PUBLIC_API_IDENTIFIER_HEADER)
      ) {
        request.headers.set(PREFER_HEADER, 'return=representation');
        request.headers.set(
          AMAZON_ADVERTISING_API_ADVERTISER_ID_HEADER,
          entityId,
        );
        request.headers.set(
          AMAZON_ADVERTISING_API_IS_IMPERSONATOR_HEADER,
          isSpoofer,
        );
        request.headers.set(AMAZON_ADVERTISING_API_CLIENT_ID_HEADER, clientId);
        request.headers.set(AMAZON_ADVERTISING_API_CSRF_DATA_HEADER, clientId);
        request.headers.set(
          AMAZON_ADVERTISING_API_MARKETPLACE_ID_HEADER,
          marketplaceId,
        );
        request.headers.set(AMAZON_ADVERTISING_API_IS_PORTAL_SERVER, isSpoofer);
        request.headers.set(AMAZON_ADS_ACCOUNT_ID_HEADER, globalAccountId);
        request.headers.set(X_AMZ_REQUEST_CSRF_TOKEN_HEADER, true);

        if (!request.headers.has(CONTENT_TYPE_HEADER)) {
          request.headers.set(CONTENT_TYPE_HEADER, 'application/json');
        }

        if (!request.headers.has(ACCEPT_HEADER)) {
          request.headers.set(ACCEPT_HEADER, 'application/json');
        }

        request.headers.delete(PUBLIC_API_IDENTIFIER_HEADER);
        request.baseURL = publicApiUrl;
      }
      return request;
    },
  );
};

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    if (
      config.method === 'put' ||
      config.method === 'post' ||
      config.method === 'delete'
    ) {
      let token = csrfTokenManager.getCsrfToken();
      if (!token) {
        token = await fetchCsrfToken();
        csrfTokenManager.setCsrfToken(token!);
      }
      config.headers['anti-csrftoken-a2z'] = token;
    } else {
      config.headers['X-Csrf-Token'] = 1;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
