import React, { ReactElement, useState } from 'react';
import { Button, Modal, ModalProps, TextButton } from '@amzn/storm-ui';
import styled from 'styled-components';

const ConfirmationButton = styled(Button)`
  margin-inline-start: ${({ theme }) => theme.spacing.small};
`;
export interface ConfirmationModalProps extends ModalProps {
  id: string;
  isLoading: boolean;
  confirmButtonText: string;
  cancelButtonText: string;
  children: string | ReactElement;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal = ({
  id,
  isOpen = false,
  header,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  children,
}: Partial<ConfirmationModalProps>) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Modal
      data-testid={id}
      isOpen={isOpen}
      header={header}
      onClose={onCancel}
      footer={
        <div>
          <TextButton
            onClick={onCancel}
            className="confirm-modal-cancel-button"
          >
            {cancelButtonText}
          </TextButton>
          <ConfirmationButton
            className="confirm-modal-approve-button"
            primary
            loading={loading}
            onClick={() => {
              setLoading(true);
              onConfirm && onConfirm();
            }}
            disabled={loading}
          >
            {confirmButtonText}
          </ConfirmationButton>
        </div>
      }
    >
      {children}
    </Modal>
  );
};
export default ConfirmationModal;
