import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'src/shared/api/api';
import { Captcha } from '@models/captcha';

const BASE_URL = '/ads-account/api/v1/';
const CAPTCHA_ENDPOINT = 'captcha';
const REDUCER_PATH = 'captchaApi';

export const captchaApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getCaptcha: builder.query<Captcha, void>({
      query: () => ({
        url: `${CAPTCHA_ENDPOINT}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCaptchaQuery } = captchaApi;
