import styled from 'styled-components';
import { Card, Divider, Icon, Text } from '@amzn/storm-ui';

export const RegistrationPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RegistrationPageInnerWrapper = styled.div`
  max-width: 840px;
  width: 100%;
`;

export const RegistrationPageTitle = styled(Text)`
  display: flex;
  justify-content: center;
  margin-block-end: ${({ theme }) => theme.spacing.xlarge};
`;

export const RegistrationPageFooterWrapper = styled.div`
  display: flex;
  margin-block-end: ${({ theme }) => theme.spacing.large};
`;

export const RegistrationPageFooterText = styled.div`
  flex: 3;
`;

export const RegistrationPageFooterActions = styled.div`
  flex: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
  display: flex;
  align-items: baseline;
  button:not(:last-child) {
    margin-inline-end: ${({ theme }) => theme.spacing.medium};
  }
`;

export const RegistrationFormWrapper = styled.form`
  width: 100%;
`;

export const RegistrationFormBusinessDetailsInfoText = styled(Text)`
  margin-block-end: ${({ theme }) => theme.spacing.base};
`;

export const RegistrationFormCardHeaderInfoWrapper = styled.div`
  display: flex;
  p {
    margin-inline-end: ${({ theme }) => theme.spacing.mini};
  }
`;

export const RegistrationFormCard = styled(Card)`
  margin-block-end: ${({ theme }) => theme.spacing.large};
`;

export const RegistrationCardContent = styled.div`
  padding: ${({ theme }) => `0 0 ${theme.spacing.large} 0`};
  :last-child {
    padding: ${({ theme }) => `${theme.spacing.large} 0 0 0`};
  }
`;

export const RegistrationCustomDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.small} 0};
`;

export const RegistrationBusinessDetailsInfoWrapper = styled.div`
  display: flex;
  margin-block-start: ${({ theme }) => theme.spacing.base};
`;

export const SellingAccountsWrapper = styled.div`
  max-height: 480px;
`;

export const SellingAccountsTitleWrapper = styled.div`
  margin-block-end: ${({ theme }) => theme.spacing.small};
`;

export const SellingAccountTileCheckboxWrapper = styled.div`
  margin-block-end: ${({ theme }) => theme.spacing.small};
`;

export const EmptyAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

export const SellingAccountPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-block-start: ${({ theme }) => theme.spacing.base};
  height: 31px;
`;

export const BusinessDetailsInfoIcon = styled(Icon)`
  margin-inline-end: ${({ theme }) => theme.spacing.mini};
  color: ${({ theme }) => theme.palette.blue[700]};
`;

export const RegistrationSwitchEmailViewWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const RegistrationSwitchEmailViewCardWrapper = styled.div`
  max-width: 960px;
  width: 100%;
`;

export const RegistrationSwitchEmailViewCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-block-end: ${({ theme }) => theme.spacing.base};
  }
`;

export const RegistrationSwitchEmailViewCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RegistrationSwitchEmailViewCardButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.base};
  flex-wrap: wrap;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.xlarge} 0 0 0;
`;

export const TextWithMargin = styled(Text)`
  margin-inline-start: ${({ theme }) => theme.spacing.micro};
`;
