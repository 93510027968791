export enum ChromeKeys {
  AccountSettings = 'account-settings',
  AccountRegistration = 'account-registration',
  RetailerAccountRegistrationPage = 'retailer-account-registration',
}

export enum MenuItemIds {
  Account = 'chrome_side_nav_account',
  Users = 'chrome_side_nav_users',
  ManagerAccount = 'chrome_side_nav_manager_accounts',
  SellingAccount = 'chrome_side_nav_selling_account',
  Brands = 'chrome_side_nav_brands',
  ThirdPartyApplication = 'chrome_side_nav_third_party_application',
  DenyList = 'chrome_side_nav_deny_list',
  DataTransparency = 'chrome_side_nav_data_transparency',
}

export enum HeaderTranslationKeys {
  AccessAndSettingsPageName = 'chrome_header_access_and_settings_page_name',
  AccessAndSettingsKey = 'chrome_header_access_and_settings_key',
}

export enum PageLayoutType {
  Standard = 'STANDARD',
  Focus = 'FOCUS',
  Light = 'LIGHT',
}

export enum ButtonState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Loading = 'LOADING',
}

export enum ButtonType {
  Primary = 'PRIMARY',
  Normal = 'NORMAL',
  Link = 'LINK',
}
