import { Text, TextProps } from '@amzn/storm-ui';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const TextContainer = styled(Text)`
  white-space: break-spaces;
`;

const StyledDynamicPart = styled.span`
  display: inline-block;
  white-space: normal;
`;

type DynamicTextProps = {
  message: string; // message with placeholder like "Error occurred, please {retry}";
  resolveComponent: (part: string) => ReactNode;
  verticalAlign?: 'top' | 'bottom' | 'baseline' | 'middle';
} & Omit<TextProps, 'ref'>;

export function DynamicText(props: DynamicTextProps) {
  const {
    message,
    resolveComponent,
    verticalAlign = 'baseline',
    ...rest
  } = props;
  const regex = /({\w+})/g;
  const parts = message.split(regex);

  return (
    <TextContainer {...rest}>
      {parts.map((part, index) => {
        if (regex.test(part)) {
          // Remove the curly braces from the matched part
          const key = part.slice(1, -1);
          // Use the resolveComponent function to get the replacement component
          return (
            <StyledDynamicPart style={{ verticalAlign }} key={index}>
              {resolveComponent(key)}
            </StyledDynamicPart>
          );
        }

        return <React.Fragment key={index}>{part}</React.Fragment>;
      })}
    </TextContainer>
  );
}
