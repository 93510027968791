import { createSlice } from '@reduxjs/toolkit';

const initialState: Partial<{
  isAccountCreationInProgress: boolean;
  isBusinessDetailsValid: boolean;
  isAccountCreationFailed: boolean;
  isOverrideBusinessEmail: boolean;
  isBusinessDetailsLoading: boolean;
  isBusinessDetailsFailed: boolean;
  isNonEndemic?: boolean;
  isRetailer?: boolean;
  isSeller?: boolean;
}> = {
  isAccountCreationInProgress: false,
  isBusinessDetailsValid: false,
  isAccountCreationFailed: false,
  isBusinessDetailsLoading: false,
  isBusinessDetailsFailed: false,
  isOverrideBusinessEmail: false,
  isNonEndemic: true,
  isRetailer: false,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setIsBusinessDetailsValid: (state, action) => {
      state.isBusinessDetailsValid = action.payload;
    },
    setIsAccountCreationLoading: (state, action) => {
      state.isAccountCreationInProgress = action.payload;
    },
    setIsAccountCreationFailed: (state, action) => {
      state.isAccountCreationFailed = action.payload;
    },
    setIsOverrideBusinessEmail: (state, action) => {
      state.isOverrideBusinessEmail = action.payload;
    },
    setIsBusinessDetailsLoading: (state, action) => {
      state.isBusinessDetailsLoading = action.payload;
    },
    setIsBusinessDetailsFailed: (state, action) => {
      state.isBusinessDetailsFailed = action.payload;
    },
    setIsRetailer: (state, action) => {
      state.isRetailer = action.payload;
    },
    setIsNonEndemic: (state, action) => {
      state.isNonEndemic = action.payload;
    },
    setIsSeller: (state, action) => {
      state.isSeller = action.payload;
    },
  },
});

export const {
  setIsBusinessDetailsValid,
  setIsAccountCreationLoading,
  setIsAccountCreationFailed,
  setIsOverrideBusinessEmail,
  setIsBusinessDetailsLoading,
  setIsBusinessDetailsFailed,
  setIsRetailer,
  setIsNonEndemic,
  setIsSeller,
} = registrationSlice.actions;
export default registrationSlice.reducer;
