import React, { createContext, useRef } from 'react';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import Spinner from '@components/Spinner';
import { DEFAULT_BUNDLE, isRTL } from '@i18n/utils';
import { MessageBundle } from '@amzn/arb-tools';

interface BundleProviderProps {
  children?: React.ReactNode;
}

export const BundleContext = createContext<MessageBundle | undefined>(
  undefined,
);

/**
 * Provider to load the default strings for the application and start the application
 * with all the strings translated and ready to use.
 */
const BundleProvider: React.FC<BundleProviderProps> = ({
  children,
}: BundleProviderProps) => {
  const [intl, isBundleLoading] = useBundle(DEFAULT_BUNDLE);
  const { localizationContext } = useLocalizationContext();
  const rtl = useRef<boolean>(isRTL(localizationContext.getLocale()));

  if (isBundleLoading) {
    return <Spinner />;
  }

  return (
    <BundleContext.Provider value={intl}>
      <div dir={rtl.current ? 'rtl' : 'ltr'}>{children}</div>
    </BundleContext.Provider>
  );
};

export default BundleProvider;
