/**
 * Cache the CSRF-token
 */
const createCsrfTokenManager = () => {
  let csrfToken: string | null = null;
  const setCsrfToken = (token: string) => {
    csrfToken = token;
  };
  const getCsrfToken = () => {
    return csrfToken;
  };

  return {
    setCsrfToken,
    getCsrfToken,
  };
};
export const csrfTokenManager = createCsrfTokenManager();
