import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@amzn/storm-ui';
import { Provider } from 'react-redux';
import { setupStore } from './shared/state/store';
import { MbmProvider } from '@amzn/react-arb-tools';
import App from './App';
import AppInitWrapper from './AppInitWrapper';
import { mbmOption } from './shared/intl';

const ReduxStore = setupStore();
const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

async function enableMocking() {
  if (process.env['MOCK_SERVER'] === 'false') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={ReduxStore}>
        <AppInitWrapper>
          <MbmProvider {...mbmOption}>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </MbmProvider>
        </AppInitWrapper>
      </Provider>
    </React.StrictMode>,
  );
});
