import React, { FC, useEffect } from 'react';
import MarketplacesComponent from './components/marketplaces';
import AccountDetailsComponent from './components/accountDetails';
import SubmitComponent from './components/submit';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../shared/state/store';
import { setChromeConfigurationType } from '../../shared/state/redux';
import { ChromeConfigurationType } from '../../shared/chrome';

const OpenRegistrationPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(setChromeConfigurationType(ChromeConfigurationType.None));
  }, []);

  return (
    <>
      <MarketplacesComponent />
      <AccountDetailsComponent />
      <SubmitComponent />
    </>
  );
};

export default OpenRegistrationPage;
