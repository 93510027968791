import React, { FC, useEffect } from 'react';
import TermsTemplate from './termsTemplate';
import { Button } from '@amzn/storm-ui';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptToken,
  selectAcceptTermsStatus,
  selectTermsForTokenStatus,
} from './redux';
import styled from 'styled-components';
import { ApiResourceState } from 'src/shared/api';
import { useBundle } from '@amzn/react-arb-tools';
import { AppDispatch } from 'src/shared/state/store';

const MaxWidthDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ResizeableWidthDiv = styled.div`
  width: 80vw;
`;

const AgreementDiv = styled.div`
  display: flex;
  margin: auto;
  justify-content: flex-end;
`;

type AgreementButtonProps = {
  token: string;
};
const AgreementButton: FC<AgreementButtonProps> = ({ token }) => {
  const [intl, isBundleLoading] = useBundle('pages.terms');
  const getTermsStatus = useSelector(selectTermsForTokenStatus);
  const submitStatus = useSelector(selectAcceptTermsStatus);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (
      submitStatus.status === ApiResourceState.SUCCEEDED ||
      submitStatus.status === ApiResourceState.FAILED
    ) {
      // scroll to top of page to show result
      window.scrollTo(0, 0);
    }
  }, [submitStatus]);
  if (getTermsStatus.status !== ApiResourceState.SUCCEEDED || isBundleLoading) {
    return null;
  }
  return (
    <AgreementDiv>
      <Button
        loading={submitStatus.status === ApiResourceState.LOADING}
        disabled={submitStatus.status !== ApiResourceState.IDLE}
        onClick={() => dispatch(acceptToken(token))}
        primary
      >
        {intl.getMessage('acceptTerms')}
      </Button>
    </AgreementDiv>
  );
};

const TermsAgreementPage: FC = () => {
  const { token } = useParams();
  if (token === undefined) {
    return null;
  }
  return (
    <MaxWidthDiv>
      <ResizeableWidthDiv>
        <TermsTemplate termsDocument="termsAgreement" fetchParams={{ token }} />
        <AgreementButton token={token} />
      </ResizeableWidthDiv>
    </MaxWidthDiv>
  );
};

export default TermsAgreementPage;
