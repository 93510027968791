import React, { FC } from 'react';
import NotFoundPage from '../errorPages/NotFoundPage';

// TODO implement after onboarding to AddressService
const AgencyRegistrationPage: FC = () => {
  // redirect to not found page
  return <NotFoundPage />;
};

export default AgencyRegistrationPage;
