import { useMemo } from 'react';
import { measurePerformance } from '../utils/performance';

interface usePerformanceProps {
  key: string;
  onComplete?: (key: string, latency: DOMHighResTimeStamp) => void;
}

const usePerformance = ({ key, onComplete }: usePerformanceProps) => {
  const { start, end, cancel } = useMemo(() => {
    return measurePerformance(key, onComplete);
  }, [key, onComplete]);

  return { start, end, cancel };
};

export default usePerformance;
