import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import subtract from 'lodash/subtract';
import { logEvent } from './takt';

const measurePerformance = (
  key: string,
  onComplete?: (key: string, latency: DOMHighResTimeStamp) => void,
) => {
  const executionInProgress: Record<string, DOMHighResTimeStamp> = {};

  if (!key) {
    throw new Error(`Valid key needs to be passed`);
  }

  const clear = () => {
    unset(executionInProgress, [key]);
  };

  const start = () => {
    set(executionInProgress, [key], performance.now());
  };

  const end = () => {
    const startTime = get(executionInProgress, [key]);
    if (!startTime) {
      console.log(`End - ${key} is not in progress`);
      return;
    }

    const latency = subtract(performance.now(), startTime);
    logEvent(key, { latency });
    console.log(`Latency ${key}: ${latency}ms`);
    onComplete && onComplete(key, latency);
    clear();
  };

  const cancel = () => {
    clear();
  };

  return { start, end, cancel };
};

export { measurePerformance };
