import React, { FC, SyntheticEvent } from 'react';
import { ENTITY_NAME_MAX_LENGTH } from '../../../shared/constants';
import { InputFormGroup, useUID } from '@amzn/storm-ui';
import { useDispatch } from 'react-redux';
import { updateAccountName } from '../redux';

const AccountDetailsComponent: FC = () => {
  const dispatch = useDispatch();

  return (
    <InputFormGroup
      id={useUID('open-reg-account-name-input')}
      className="open-reg-account-name-input"
      onChange={(event: SyntheticEvent<HTMLInputElement>) =>
        dispatch(updateAccountName(event.currentTarget.value))
      }
      label=""
      fullWidth
      maxLength={ENTITY_NAME_MAX_LENGTH}
    >
      Account Name
    </InputFormGroup>
  );
};

export default AccountDetailsComponent;
