import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertProps } from '@amzn/storm-ui';
import { ReactNode } from 'react';
import remove from 'lodash/remove';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

export type AlertPlacement = 'mainView';

export type AlertConfig = {
  id: string;
  type: AlertProps['type'];
  placement?: AlertPlacement;
  message?: ReactNode;
  header?: ReactNode;
  withCloseButton?: boolean;
  onClose?: (id: string) => void;
  duration?: number;
};

export interface AlertState {
  alerts: AlertConfig[];
}

const initialState: AlertState = {
  alerts: [],
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<AlertConfig>) => {
      const id = action.payload.id;
      if (id && !isEmpty(find(state.alerts, { id }))) {
        return;
      }

      state.alerts.push({
        ...action.payload,
        placement: action.payload.placement || 'mainView',
      });
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      remove(state.alerts, { id });
    },
    clearAlerts: (state) => {
      state.alerts = [];
    },
  },
});

export const { addAlert, removeAlert, clearAlerts } = alertSlice.actions;

export default alertSlice.reducer;
