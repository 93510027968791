import React, { useMemo } from 'react';
import { SellingAccount } from '@models/account';
import useIntl from '@i18n/useIntl';
import { getSellingAccountType } from '@components/form/account/utils';
import ReadOnlyFormField from '@components/form/ReadOnlyFormRow';
import {
  SELLING_ACCOUNT_FIELD_ID,
  SELLING_ACCOUNT_FIELD_LABEL,
  SELLING_ACCOUNT_LINK,
  SELLING_ACCOUNT_LINK_FIELD_ID,
  SELLING_ACCOUNT_TYPE_ID,
} from 'src/pages/accountSettings/components/AccountDetails/constants';
import { Link, TextButton, Text } from '@amzn/storm-ui';
import { SellingAccountWrapper } from 'src/pages/accountSettings/style';

interface SellingAccountsProps {
  sellingAccounts: SellingAccount[];
}
const AccountDetailsSellingAccount = ({
  sellingAccounts,
}: SellingAccountsProps) => {
  const { intl } = useIntl();
  const [sellingAccount] = sellingAccounts;
  const sellingAccountType = useMemo(
    () => getSellingAccountType(intl, sellingAccount.sellingProgram),
    [sellingAccount.sellingProgram],
  );

  return (
    <>
      <ReadOnlyFormField
        label={intl.getMessage(SELLING_ACCOUNT_FIELD_LABEL)}
        id={SELLING_ACCOUNT_FIELD_ID}
      >
        <SellingAccountWrapper>
          <Text type="span">{sellingAccount.displayName}</Text>
          {sellingAccountType && (
            <Text type="span" data-testid={SELLING_ACCOUNT_TYPE_ID}>
              ({sellingAccountType})
            </Text>
          )}
        </SellingAccountWrapper>
      </ReadOnlyFormField>

      <ReadOnlyFormField label={''} id={SELLING_ACCOUNT_LINK_FIELD_ID}>
        <TextButton>
          <Link href="/" target="_blank" external={true}>
            {intl.getMessage(SELLING_ACCOUNT_LINK)}
          </Link>
        </TextButton>
      </ReadOnlyFormField>
    </>
  );
};

export default AccountDetailsSellingAccount;
