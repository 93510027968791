import React from 'react';
import { Text, TextProps } from '@amzn/storm-ui';

type ObjectInfoProps = {
  value: {
    title: string;
    description?: string;
  };
} & Omit<TextProps, 'ref'>;

const ObjectInfo = (props: ObjectInfoProps) => {
  const {
    value,
    fontSize = 'base',
    withMargin = false,
    type = 'h3',
    ...rest
  } = props;
  const { title, description } = value;

  return (
    <>
      <Text type={type} fontSize={fontSize} withMargin={withMargin} {...rest}>
        {title}
      </Text>
      {description && (
        <Text fontSize={'base'} textColor={'base'}>
          {description}
        </Text>
      )}
    </>
  );
};

export default ObjectInfo;
