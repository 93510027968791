import styled from 'styled-components';
import { FormRow } from '@amzn/storm-ui-column-form-kit';
import { Text } from '@amzn/storm-ui';

export const ViewWrapper = styled.div`
  display: flex;
`;

export const ControllersWrapper = styled.div`
  flex-direction: row;
  max-width: 700px;
  flex: 1 1 auto;
`;

export const CustomFormRow = styled(FormRow)`
  :last-child {
    margin: 0;
  }
  align-items: center;
`;

export const ActionsButtonsWrapper = styled.div`
  flex: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
  display: flex;
  align-items: baseline;
  button:last-child {
    margin-inline-start: ${({ theme }) => theme.spacing.medium};
  }
`;

export const TextWithSeparator = styled(Text)`
  &:not(:last-of-type):after {
    content: ', ';
  }
`;
