/**
 * Chrome API
 */
export const EXIT_FOCUS_VIEW = 'ExitFocusView';

/** Errors * */
export const PAGE_LAYOUT_ERROR =
  'There was a problem setting the chrome page layout, message: ';
export const CHROME_HEADER_ERROR =
  'There was a problem creating the chrome header components, message: ';
export const CHROME_NAVIGATION_ERROR =
  'There was a problem creating the chrome side navigation, message: ';
export const HEADER_BUTTONS_ERROR =
  'There was a problem creating the chrome header buttons, message: ';
export const UPDATE_HEADER_BUTTONS_ERROR =
  'There was a problem updating the chrome header buttons, message: ';
export const HEADER_ATTRIBUTES_ERROR =
  'There was a problem creating the chrome header attributes, message: ';
export const UPDATE_PAGE_NAME_ERROR =
  'There was a problem updating the page name, message: ';
export const SUBSECTION_ERROR =
  'There was a problem creating header subsections, message:';
