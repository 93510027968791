import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountInfoEditMode: false,
  businessDetailsEditMode: false,
};

const accountSettingsSlice = createSlice({
  name: 'accountSetting',
  initialState,
  reducers: {
    toggleAccountInfoEditMode: (state) => {
      state.accountInfoEditMode = !state.accountInfoEditMode;
    },
    toggleBusinessDetailsEditMode: (state) => {
      state.businessDetailsEditMode = !state.businessDetailsEditMode;
    },
    setAccountInfoEditMode: (state, action) => {
      state.accountInfoEditMode = action.payload;
    },
    setBusinessDetailsEditMode: (state, action) => {
      state.businessDetailsEditMode = action.payload;
    },
  },
});

export const {
  toggleAccountInfoEditMode,
  toggleBusinessDetailsEditMode,
  setAccountInfoEditMode,
  setBusinessDetailsEditMode,
} = accountSettingsSlice.actions;
export default accountSettingsSlice.reducer;
