import React, { Fragment, FC, useEffect } from 'react';
import { Card, Text, Icon } from '@amzn/storm-ui';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectMarketplaces,
  selectFetchMarketplacesStatus,
  addSelectedMarketplace,
  removeSelectedMarketplace,
  fetchMarketplaces,
} from '../redux';
import { Marketplace } from '../model';
import { AppDispatch } from '../../../shared/state/store';
import { useBundle } from '@amzn/react-arb-tools';
import { ApiResourceState } from '../../../shared/api';

const MarketplacesComponent: FC = () => {
  const marketplaces: Marketplace[] = useSelector(selectMarketplaces);
  const fetchStatus = useSelector(selectFetchMarketplacesStatus);
  const dispatch = useDispatch<AppDispatch>();
  const [intl, isBundleLoading] = useBundle('open_registration');
  useEffect(() => {
    dispatch(fetchMarketplaces());
  }, []);

  if (isBundleLoading) {
    return null;
  }

  const component =
    fetchStatus === ApiResourceState.LOADING ? (
      <Icon type="spinner" />
    ) : (
      marketplaces.map((m) => (
        <Card
          key={m.obfuscatedId}
          onClick={() =>
            dispatch(
              m.selected
                ? removeSelectedMarketplace(m.obfuscatedId)
                : addSelectedMarketplace(m.obfuscatedId),
            )
          }
        >
          <Text>{`${m.obfuscatedId} : ${m.selected}`}</Text>
        </Card>
      ))
    );

  return (
    <Fragment>
      {intl.getMessage('aac_regional_marketplace_us')}
      {component}
    </Fragment>
  );
};

export default MarketplacesComponent;
