import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalProps } from '@amzn/storm-ui';
import { Captcha } from '@models/captcha';
import styled from 'styled-components';

export interface CaptchaModalProps extends ModalProps {
  id: string;
  captchaOptions: Partial<Captcha>;
}
export const IframeCaptcha = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

export const StyledDiv = styled.div`
  height: 550px;
`;

const CaptchaModal = ({
  id,
  isOpen = false,
  onClose = undefined,
  hasCloseButton = false,
  captchaOptions,
}: CaptchaModalProps) => {
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (iframeRef.current) {
        const observer = new IntersectionObserver(([entry]) => {
          setIsIframeVisible(entry.isIntersecting);
        });

        observer.observe(iframeRef.current);

        return () => {
          if (iframeRef.current) {
            observer.unobserve(iframeRef.current);
          }
        };
      }
    }, 0);
  }, [isOpen]);

  useEffect(() => {
    if (isIframeVisible && window.acic?.setupACIC) {
      window.acic.setupACIC(captchaOptions);
    }
  }, [isIframeVisible]);

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      hasCloseButton={hasCloseButton}
      onClose={onClose}
    >
      <StyledDiv className="captcha-container">
        <IframeCaptcha
          ref={iframeRef}
          data-testid={captchaOptions['data-iframe-id']}
          id={captchaOptions['data-iframe-id']}
          sandbox="allow-scripts allow-same-origin allow-forms"
        />
      </StyledDiv>
    </Modal>
  );
};
export default CaptchaModal;
