import React from 'react';
import { Text } from '@amzn/storm-ui';
import useIntl from '@i18n/useIntl';
import { InvitationStatusCode } from '@services/registration/types';
import { getInvitationStatus } from './utils';
import { ErrorBanner } from '@components/notifications/banner/Banners';
import {
  REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
  REGISTRATION_GENERAL_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
} from '@components/form/registration/constants';
import { RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_BANNER_ID } from 'src/pages/retailerAccountRegistration/constants';

interface RetailerAccountRegistrationErrorBannerProps {
  errorCode: InvitationStatusCode;
  loginEmail?: string;
}

const RetailerAccountRegistrationErrorBanner = ({
  errorCode,
  loginEmail,
}: RetailerAccountRegistrationErrorBannerProps) => {
  const { intl } = useIntl();
  let errorDetails = null;

  const invitationStatusMap = getInvitationStatus(intl, loginEmail);
  errorDetails = invitationStatusMap[
    errorCode as keyof typeof invitationStatusMap
  ] || {
    title: intl.getMessage(
      REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
    ),
    message: intl.getMessage(
      REGISTRATION_GENERAL_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
    ),
  };

  return (
    <ErrorBanner
      id={RETAILER_ACCOUNT_REGISTRATION_INVALID_TOKEN_BANNER_ID}
      duration={0}
    >
      <>
        <Text type="h4">{errorDetails.title}</Text>
        <Text>{errorDetails.message}</Text>
      </>
    </ErrorBanner>
  );
};

export default RetailerAccountRegistrationErrorBanner;
